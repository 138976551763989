import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

import { CoreLocale } from '../../../../../../schemas/interfaces/core-locale'
import { translateMatDialogContent } from '../../../../../utility/translate-mat-dialog-content'

interface DialogData {
  locale?: CoreLocale
}

@Component({
  selector: 'wfa-limit-dialog',
  templateUrl: './limit-dialog.component.html',
  styleUrls: [
    './limit-dialog.component.scss',
    '../../../../../../scss/button.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class LimitDialogComponent {
  readonly faXmark = faXmark

  constructor(
    private dialogRef: MatDialogRef<LimitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
  ) {
    translateMatDialogContent(this.translate, this.data.locale)
  }

  continue() {
    this.dialogRef.close()
  }
}
