import { set } from '../../../utility/lodash-alternatives/set'
import academicTitle from './academic-title.json'
import birthdate from './birthdate.json'
import contact from './contact.json'
import country from './country.json'
import gender from './gender.json'
import sendingInformation from './sending-information.json'

export const customQuestionTypes = [
  academicTitle,
  birthdate,
  contact,
  country,
  gender,
  sendingInformation,
]

export const questionTypeTitles = customQuestionTypes.reduce((acc, item) => {
  set(acc, item.name, item.title)
  return acc
}, {})
