import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { WfaEnvService } from '../../../environments/wfa-env.service'
import {
  AnyWfaForm,
  Id,
  PraxisId,
  WfaForm,
  WfaFormDraftInfo,
  WfaFormId,
  WfaFormInfo,
  WfaFormStatus,
} from '../../../schemas/interfaces'
import { PraxisDetails } from '../../../schemas/interfaces/wfb'
import { Maybe } from '../../../types/maybe'
import { nullish } from '../../../utility/nullish'
import { GetWfaFormRouteResponse200 } from './../../../schemas/interfaces/wfb/get-wfa-form.route-response.200'
import { getUrl as _getUrl } from './../../utility/get-url'
import { httpOptions } from './../../utility/http-params'
import { sortByWfaFormId } from './utils'

export interface FormAndDraftInfos {
  formInfos: WfaFormInfo[]
  draftInfos: WfaFormDraftInfo[]
}
export const emptyInfos = { formInfos: [], draftInfos: [] }

@Injectable({
  providedIn: null,
})
export class ZsSupportCommsService {
  constructor(
    private httpClient: HttpClient,
    private wfaEnvService: WfaEnvService,
  ) {}

  private getUrl(path: string): string {
    return _getUrl(this.wfaEnvService, path)
  }

  createBlankDraft(praxisId: PraxisId, wfaFormId: WfaFormId): Observable<any> {
    const url = this.getUrl(`wfaFormDraft/create`)
    const body = {
      blankDraft: {
        praxisId,
        wfaFormId,
      },
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  createFormDraft(wfaFormVersionId: Id): Observable<any> {
    const url = this.getUrl(`wfaFormDraft/create`)
    const body = {
      wfaFormVersionId,
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  createFormDraftUsingForm(wfaForm: WfaForm): Observable<any> {
    const url = this.getUrl(`wfaFormDraft/create`)
    const body = {
      wfaForm,
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  deleteFormDraft(wfaFormVersionId: Id): Observable<any> {
    const url = this.getUrl(`wfaFormDraft/delete`)
    const body = {
      wfaFormVersionId,
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  getForm(
    praxisId: PraxisId | undefined,
    wfaFormId: WfaFormId | undefined,
    loadAllFormVersions = false,
  ): Observable<GetWfaFormRouteResponse200 | null> {
    if (praxisId === undefined || wfaFormId === undefined) {
      console.error(
        `[getAllFormVersions]: can not load WfaForms for praxisId ${praxisId} and wfaFormId ${wfaFormId}.`,
      )
      return of(null)
    }

    const url = this.getUrl(
      `getWfaForm?praxisId=${praxisId}&wfaFormId=${wfaFormId}&loadAllVersions=${loadAllFormVersions}`,
    )
    return this.httpClient.get(url) as Observable<GetWfaFormRouteResponse200>
  }

  getDraft(wfaFormVersionId: Maybe<Id>): Observable<Maybe<WfaForm>> {
    if (nullish(wfaFormVersionId)) {
      console.error(
        `[getDraft]: could not load FormDraft for wfaFormVersionId ${wfaFormVersionId}.`,
      )
      return of(null)
    }
    const url = this.getUrl(
      `wfaFormDraft/read?wfaFormVersionId=${wfaFormVersionId}`,
    )
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        const drafts = response?.queryResult?.wfaFormDrafts
        if (drafts?.length !== 1) return null
        return drafts[0]
      }),
    )
  }

  getFormAndDraftInfos(
    praxisId: Maybe<PraxisId>,
  ): Observable<FormAndDraftInfos> {
    if (nullish(praxisId)) {
      console.error(
        `[getFormAndDraftInfos]: could not load FormAndDraftInfos for praxisId ${praxisId}.`,
      )
      return of(emptyInfos)
    }
    const url = this.getUrl(
      `getWfaFormInfos?praxisId=${praxisId}&includeFormDraftInfos=true`,
    )
    return this.httpClient.get(url).pipe(
      map((response: any) => ({
        formInfos: response?.queryResult.sort(sortByWfaFormId),
        draftInfos: response?.formDraftInfos.sort(sortByWfaFormId),
      })),
    )
  }

  getFormInfos(praxisId: Maybe<PraxisId>): Observable<WfaFormInfo[]> {
    return this.getFormAndDraftInfos(praxisId).pipe(
      map((infos: FormAndDraftInfos) => infos.formInfos),
    )
  }

  loadAllPraxisDetails(): Observable<PraxisDetails[]> {
    const url = this.getUrl(`getInstanceIdentifiers`)
    return this.httpClient
      .get(url)
      .pipe(map((response: any) => response?.queryResult?.instanceDetails))
  }

  publishForm(wfaForm: WfaForm): Observable<any> {
    const url = this.getUrl(`copyDynamicWfaForm`)
    const body = {
      wfaForm,
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  publishFormDraft(wfaFormVersionId: Id): Observable<any> {
    const url = this.getUrl(`wfaFormDraft/publish`)
    const body = {
      wfaFormVersionId,
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  updateFormPermalink(
    praxisId: PraxisId,
    wfaFormId: WfaFormId,
  ): Observable<any> {
    const url = this.getUrl(`updateWfaFormPermalink`)
    const body = {
      praxisId,
      wfaFormId,
    }
    return this.httpClient.post(url, body, httpOptions)
  }

  updateFormStatus(
    praxisId: PraxisId,
    status: WfaFormStatus,
    wfaFormId: WfaFormId,
  ): Observable<any> {
    const url = this.getUrl(`updateWfaFormStatus`)
    const body = {
      praxisId,
      status,
      wfaFormIds: [wfaFormId],
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  unlockWfaForm(
    praxisId: Maybe<PraxisId>,
    wfaFormVersionId: Maybe<Id>,
    newWfaFormId: Maybe<WfaFormId>,
  ): Observable<any> {
    if (
      nullish(praxisId) ||
      nullish(wfaFormVersionId) ||
      nullish(newWfaFormId)
    ) {
      console.error(
        `[unlockWfaForm]: could not unlock WfaForm with praxisId ${praxisId}, wfaFormVersionId ${wfaFormVersionId}, newWfaFormId ${newWfaFormId}.`,
      )
      return of(null)
    }

    const url = this.getUrl(`copyDynamicWfaForm`)
    const body = {
      praxisId,
      wfaFormVersionId,
      newWfaFormId,
    }

    return this.httpClient.post(url, body, httpOptions)
  }

  unlockWfaFormWithForm(wfaForm: Maybe<AnyWfaForm>): Observable<any> {
    if (nullish(wfaForm)) {
      console.error(
        `[unlockWfaFormWithForm]: could not unlock WfaForm ${JSON.stringify(
          wfaForm,
        )}.`,
      )
      return of(null)
    }

    const url = this.getUrl(`copyDynamicWfaForm`)
    const body = {
      wfaForm,
    }

    return this.httpClient.post(url, body, httpOptions)
  }
}
