import { WfaForm } from '../../../schemas/interfaces/wfa-form'
import adi3232 from './bugs/adi-3232.json'
import depraecatedWfaFoerm from './depraecated-wfa-foerm.json'
import dummyForm from './dummy-form-01.json'
import extendedQuestionMix from './extended-question-mix.json'
import fallbackLanguage from './fallback-language.json'
import fileUpload from './file-upload.json'
import multiPage from './multi-page.json'
import multiRow from './multi-row.json'
import questionMix from './question-mix.json'
import questionMixWithPassword from './question-mix-with-password.json'
import questionMixWithWfaKeys from './question-mix-with-wfa-keys.json'
import requiredOnCondition from './required-on-condition.json'
import validators from './validators.json'
import visibleIfOnPageLevel from './visible-if-on-page-level.json'
import visibleOnCondition from './visible-on-condition.json'

export const testing = {
  adi3232: adi3232 as unknown as WfaForm,
  depraecatedWfaFoerm: depraecatedWfaFoerm as unknown as WfaForm,
  dummyForm: dummyForm as WfaForm,
  extendedQuestionMix: extendedQuestionMix as WfaForm,
  fallbackLanguage: fallbackLanguage as WfaForm,
  fileUpload: fileUpload as WfaForm,
  multiPage: multiPage as WfaForm,
  multiRow: multiRow as WfaForm,
  questionMix: questionMix as WfaForm,
  questionMixWithPassword: questionMixWithPassword as WfaForm,
  questionMixWithWfaKeys: questionMixWithWfaKeys as WfaForm,
  requiredOnCondition: requiredOnCondition as WfaForm,
  validators: validators as WfaForm,
  visibleIfOnPageLevel: visibleIfOnPageLevel as WfaForm,
  visibleOnCondition: visibleOnCondition as WfaForm,
} as const
