import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { staticIdDict } from '../../wfb/populate-test-db/praxis-id.dict.test-db'
import { DoctorFormTableManagementComponent } from '../adis/doctor-form-table-management/doctor-form-table-management.component'
import { notNullish } from './../../utility/nullish'

@Component({
  standalone: true,
  selector: 'app-test-doctor-form-table-management',
  template: `<wfa-doctor-form-table-management
    [praxisId]="praxisId"
  ></wfa-doctor-form-table-management>`,
  imports: [DoctorFormTableManagementComponent],
})
export class TestDoctorFormTableManagementComponent {
  praxisId = staticIdDict.zollsoft

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation()
    const navState = navigation?.extras.state
    if (notNullish(navState) && notNullish(navState['navData']['praxisId'])) {
      this.praxisId = navState['navData']['praxisId']
    }
  }
}
