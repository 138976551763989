import {
  propertyNames as creatorPropertyNames,
  CoreLocale,
  LocString,
} from '@arzt-direkt/wfa-definitions'
import { nullish, Maybe, get, set } from '@arzt-direkt/wfa-generic-utils'
import { Serializer, surveyLocalization } from 'survey-core'

type LocStringWithDefault = Required<Pick<LocString, 'default'>>
type LocalizableValue = Maybe<string | LocString | LocStringWithDefault>
export const localizableProperties = getLocalizableProperties()

/**
 * Properties in a JSON that are localized (`isLocalizable` is `true`) should always be specified as `LocString`.
 * This is currently only the case if the value for a property is set in multiple languages.
 * If the value is only available in one language, it is stored as a string.
 * However, the value cannot be parsed as a string either in the AD app or on the tomedo® server.
 * Both the app and the server can only interpret `LocString`s correctly.
 *
 * @param json JSON that is created by the editor
 */
export const localizeCreatorJson = (
  json: Record<string, unknown>,
  locale?: CoreLocale,
) => localizePropertyValues(json, locale)

/**
 * This function goes through the list of all currently available editor properties and filters them according to their localizability.
 * @returns all properties that are localizable.
 */
function getLocalizableProperties(): string[] {
  const customLocalazibleProperties = ['checkbox']
  const localizableProperties: string[] = creatorPropertyNames.flatMap(
    (name: string) =>
      Serializer.getAllPropertiesByName(name)
        .filter(
          (prop) =>
            prop.isLocalizable === true ||
            customLocalazibleProperties.includes(prop.name),
        )
        .map((prop) => prop.name),
  )
  return [...new Set(localizableProperties)]
}

/**
 * @param obj is mutated
 */
function localizePropertyValues(
  obj: Record<string, unknown>,
  locale?: CoreLocale,
): void {
  if (typeof obj !== 'object' || nullish(obj)) return
  if (Array.isArray(obj))
    obj.map((element) => localizePropertyValues(element, locale))

  Object.entries(obj).forEach(([key, value]) => {
    if (localizableProperties.includes(key))
      obj[key] = generateLocString(value as LocalizableValue, locale)
    if (!localizableProperties.includes(key) && typeof value === 'object')
      localizePropertyValues(value as Record<string, unknown>, locale)
  })

  localizeCheckboxChoices(obj, locale)
}

/**
 * @param obj is mutated
 */
function localizeCheckboxChoices(
  _obj: Record<string, unknown>,
  locale?: CoreLocale,
): void {
  const obj = _obj as { type: 'checkbox'; choices: LocalizableValue[] }
  if (
    obj.type !== 'checkbox' ||
    get(obj, 'choices.length') < 1 ||
    typeof get(obj, 'choices.0') !== 'string'
  )
    return
  const localizedChoices = obj.choices.map((choice) => ({
    value: choice,
    text: generateLocString(choice, locale),
  }))
  set(obj, 'choices', localizedChoices)
}

function generateLocString(
  value: LocalizableValue,
  locale?: CoreLocale,
): Maybe<LocString> {
  if (nullish(value)) return value
  if (typeof value === 'string') return { de: value }
  if (value.default)
    return replaceDefaultKey(value as LocStringWithDefault, locale)
  return value as LocString
}

function replaceDefaultKey(
  obj: LocStringWithDefault,
  locale?: CoreLocale,
): LocString {
  const localeKey = locale ?? surveyLocalization.defaultLocale
  const objWithLocaleKey = { [localeKey]: obj.default }
  const locString = obj as LocString
  delete locString.default
  return { ...objWithLocaleKey, ...locString }
}
