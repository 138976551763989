import { SurveyjsPropertyInfo } from '../../../../../schemas/interfaces'

export const allowMultiple: SurveyjsPropertyInfo = {
  name: 'allowMultiple',
  surveyElements: ['file'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: true,
    },
  ],
}
