import Joi from 'joi'

import { zollsoftForms } from '../../../assets/json/zollsoft/_zollsoft-forms.barrel'
import { WfaForm_v1_5_0 } from '../../interfaces/wfa-form-versions/wfa-form-v1-5-0'
import { CoreLocaleJoi } from '../core-locale.joi'
import { IdJoi } from '../id.joi'
import { LocStringJoi } from '../loc-string.joi'
import { PraxisIdJoi } from '../praxis-id.joi'
import { RelaxedWfaFormIdJoi } from '../wfa-form-id.joi'

export const WfaFormJoi: Joi.ObjectSchema<WfaForm_v1_5_0> = Joi.object()
  .keys({
    description: LocStringJoi.optional(),
    locale: CoreLocaleJoi.optional().description(
      'Currently necessary for legacy arzt-direkt settings.',
    ),
    praxisId: PraxisIdJoi.optional(),
    pages: Joi.array().items(Joi.object()),
    title: LocStringJoi.required(),
    wfaFormId: RelaxedWfaFormIdJoi.required(),
    wfaFormVersionId: IdJoi.required(),
    wfaVersion: Joi.string().required(),
  })
  .unknown()
  .meta({ className: 'WfaForm_v1_5_0' })
  .example(zollsoftForms.personalData)
