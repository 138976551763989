import Joi from 'joi'

import { ErrorLocation } from '../../../interfaces/wfa-question/base-question/error-location'

export const errorLocationDict: Record<string, ErrorLocation> = {
  default: 'default',
  top: 'top',
  bottom: 'bottom',
} as const

export const errorLocations = Object.values(errorLocationDict)

export const ErrorLocationJoi = Joi.string()
  .valid(...errorLocations)
  .meta({
    className: 'ErrorLocation',
  })
  .example('default')
