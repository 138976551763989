import { WfaFormResponse } from '../interfaces/wfa-form-response'
import surveyjsResponse from './surveyjs-response.joi.example.json'

export const wfaFormResponse: WfaFormResponse = {
  linkToCompleteSurveyInArztDirekt:
    'http://localhost:4200/wfa/editFormResponse&praxisId=651161879124139e2489b1e2&wfaFormId=personal-data&responseId=653a2dc8e0240b44e8f084b1',
  origin: 'missing',
  praxisId: '651161879124139e2489b1e2',
  responseId: '653a2dc8e0240b44e8f084b1',
  submittedAt: undefined,
  wfaFormId: 'personal-data',
  wfaFormVersionId: '6516d06ced8700dbf2c55180',
  surveyjsResponse,
}
