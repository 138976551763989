import { InjectionToken } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

import { CoreLocale } from '../../schemas/interfaces/core-locale'
import { LicenseTier } from '../types/license-tier'
import { EditorOrigin } from './../../schemas/interfaces/app/editor/editor-origin'
import { PraxisId } from './../../schemas/interfaces/praxis-id'
import { Maybe } from './../../types/maybe'
import { notNullish } from './../../utility/nullish'
import {
  AreYouSureParams,
  AreYouSureUserInput,
} from './../utility-components/are-you-sure.component'

// environment can be changed on runtime and requires re-render
export interface EditorEnv {
  json?: unknown
  licenseTier: LicenseTier
  locale: CoreLocale
  origin: EditorOrigin
  praxisId?: Maybe<PraxisId>
}

export const initialEditorEnv: EditorEnv = {
  locale: 'de',
  licenseTier: 'extended',
  origin: 'orphan',
  json: {},
}

// static environment is not changed on runtime
export interface SupportEditorEnv {
  isUsedIn: 'supportEditor'
}

export interface TestEditorEnv {
  isUsedIn: 'testEditor'
}

export interface AdisEditorEnv {
  isUsedIn: 'adisEditor'
  deleteDraftModal: AreYouSureParams
}

export type StaticEditorEnv = SupportEditorEnv | AdisEditorEnv | TestEditorEnv

export const STATIC_EDITOR_ENV = new InjectionToken<StaticEditorEnv>(
  'STATIC_EDITOR_ENV',
)

export const adisEditorEnv: AdisEditorEnv = {
  isUsedIn: 'adisEditor',
  deleteDraftModal: {
    show$: new BehaviorSubject<boolean>(false),
    userInput$: new Subject<AreYouSureUserInput>(),
  },
}

export const supportEditorEnv: SupportEditorEnv = {
  isUsedIn: 'supportEditor',
}

export const testEditorEnv: TestEditorEnv = {
  isUsedIn: 'testEditor',
}

export function isAdisEditorEnv(s: StaticEditorEnv): s is AdisEditorEnv {
  const ee = s as AdisEditorEnv
  return ee.isUsedIn === 'adisEditor' && notNullish(ee.deleteDraftModal)
}
