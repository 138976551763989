import dayjs from 'dayjs'
import { catchError, fromEvent, map, Observable, of, take } from 'rxjs'

import { newWfaMessage, WfaMessage } from '../../../../../types/wfa-message'
import { FileUploadSettings } from '../../settings'
import { calculateNewImageSize, Size } from './calculate-new-image-size'

export function scaleImage(
  base64String: string,
  settings: FileUploadSettings,
): Observable<string | WfaMessage> {
  const { scaleSize, quality, saveType } = settings.image
  const startTime = dayjs().valueOf()
  const img = new Image()
  img.src = base64String

  return fromEvent(img, 'load').pipe(
    take(1),
    map((_) => {
      const originalImageSize: Size = [img.naturalWidth, img.naturalHeight]
      const newImageSize = calculateNewImageSize(originalImageSize, scaleSize)

      const canvasSize = newImageSize
      const [canvas, context] = createCanvas(canvasSize)
      context.drawImage(img, 0, 0, newImageSize[0], newImageSize[1])

      const endTime = dayjs().valueOf()
      const duration = endTime - startTime
      console.log(
        `image rescaled from (W, H) = (${originalImageSize}) to (${newImageSize}) in ${duration} ms, saved as ${saveType}`,
      )

      return canvas.toDataURL(saveType, quality)
    }),
    catchError((error) => {
      return of(newWfaMessage(`Fehler beim Laden des Bildes: ${error.message}`))
    }),
  )
}

function createCanvas(
  canvasSize: Size,
): [HTMLCanvasElement, CanvasRenderingContext2D] {
  const [width, height] = canvasSize
  const canvas = document.createElement('canvas') as HTMLCanvasElement
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d', {
    alpha: false,
  }) as CanvasRenderingContext2D
  context.fillStyle = 'white'
  context.fillRect(0, 0, canvas.width, canvas.height)
  return [canvas, context]
}
