import { Types } from 'mongoose'

import { WfaForm, WfaFormResponse } from '../../schemas/interfaces'
import { WfaMessage } from '../../types/wfa-message'

export function createWfaFormResponse(
  wfaForm: WfaForm,
): WfaMessage | WfaFormResponse {
  const { wfaFormId, wfaFormVersionId, praxisId } = wfaForm
  return {
    responseId: new Types.ObjectId().toString(),
    wfaFormId,
    wfaFormVersionId,
    praxisId: praxisId ?? '000000000000000000000000',
    surveyjsResponse: {},
  }
}
