import { omit } from 'lodash-es'

import {
  MostRelaxedWfaForm,
  WfaFormResponse,
} from '../../../../schemas/interfaces'
import { SurveyjsFileResponseFormat } from '../../../../schemas/interfaces/file-upload'
import { WfaFormResponseWithFiles } from '../../../../schemas/interfaces/file-upload'
import { getQuestions } from '../../../../utility/get-questions'
import { notNullish, nullish } from '../../../../utility/nullish'

export function separateFilesFromFormResponse(
  wfaForm: MostRelaxedWfaForm,
  wfaFormResponse: WfaFormResponse,
): WfaFormResponseWithFiles {
  const fileQuestionNames = getFileQuestionNames(wfaForm)
  const files = getFilesFromFormResponse(wfaFormResponse, fileQuestionNames)
  const formResponseWithoutFiles = removeFilesFromFormResponse(
    wfaFormResponse,
    fileQuestionNames,
  )
  return {
    formResponse: formResponseWithoutFiles,
    files: files,
  }
}

export function getFileQuestionNames(wfaForm: MostRelaxedWfaForm): string[] {
  const questions = getQuestions(wfaForm)
  return questions
    .map((question) => {
      if (question.type === 'file') return question.name
      return null
    })
    .filter(notNullish)
}

export function getFilesFromFormResponse(
  wfaFormResponse: WfaFormResponse,
  fileQuestionNames: string[],
): SurveyjsFileResponseFormat[] {
  const { surveyjsResponse } = wfaFormResponse
  if (nullish(surveyjsResponse)) return []
  return fileQuestionNames
    .map((name) => {
      return surveyjsResponse[name] as SurveyjsFileResponseFormat[]
    })
    .filter(notNullish)
    .flat()
}

export function removeFilesFromFormResponse(
  wfaFormResponse: WfaFormResponse,
  fileQuestionNames: string[],
): WfaFormResponse {
  const { surveyjsResponse } = wfaFormResponse
  if (nullish(surveyjsResponse)) return wfaFormResponse

  return {
    ...wfaFormResponse,
    surveyjsResponse: omit(surveyjsResponse, fileQuestionNames),
  }
}
