import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

import { WfaEnvService } from '../../environments/wfa-env.service'
import { WfaFormDraft } from '../../schemas/interfaces'
import { Maybe } from '../../types/maybe'
import { nullish } from '../../utility/nullish'
import { httpOptions } from './../utility/http-params'

@Injectable({
  providedIn: null,
})
export class EditorDataService {
  constructor(
    private httpClient: HttpClient,
    private wfaEnvService: WfaEnvService,
  ) {}

  private getUrl(path: string): string {
    if (this.wfaEnvService === undefined) return ''
    return `${this.wfaEnvService.serverUrl}${this.wfaEnvService.serverUrlSubdirectory}/${path}`
  }

  public wfaUpdateWfaFormDraft(
    wfaFormDraft: Maybe<WfaFormDraft>,
  ): Observable<any> {
    if (nullish(wfaFormDraft)) {
      console.error(
        `[wfaUpdateWfaFormDraft]: could not update nullish wfaFormDraft`,
      )
      return of(null)
    }

    const url = this.getUrl(`wfaFormDraft/update`)
    const body = {
      wfaForm: wfaFormDraft,
    }

    return this.httpClient.post(url, body, httpOptions)
  }
}
