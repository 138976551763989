import { ViewerEnvState } from '../interfaces/viewer-env-state'
import { IsEmbeddedIn } from './../interfaces/is-embedded-in'

export const defaultViewerEnvState: ViewerEnvState = {
  locale: 'de',
  isEmbeddedIn: 'arztDirekt',
  displayOption: 'editable',
}

export const initialViewerEnvStateDict: Record<IsEmbeddedIn, ViewerEnvState> = {
  arztDirekt: { ...defaultViewerEnvState, isEmbeddedIn: 'arztDirekt' },
  iOsTomedo: { ...defaultViewerEnvState, isEmbeddedIn: 'iOsTomedo' },
  macOsTomedo: { ...defaultViewerEnvState, isEmbeddedIn: 'macOsTomedo' },
  adStepper: { ...defaultViewerEnvState, isEmbeddedIn: 'adStepper' },
}
