import { CustomPropertyInfo } from '../../../../../schemas/interfaces/custom-property-info'

const praxisId: CustomPropertyInfo = {
  name: 'praxisId',
  type: 'string',
  visible: false,
}

const surveyElements = ['survey']

export const surveyElementsWithPraxisId = surveyElements.reduce(
  (acc, item) => {
    acc[item] = praxisId
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
