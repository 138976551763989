import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'

import { wfaVersion } from './../../wfa-version'

@Component({
  selector: 'wfa-version',
  template: `<div class="center-box">
    <div>wfaVersion: {{ version }}</div>
  </div>`,
  standalone: true,
  imports: [CommonModule],
  styles: `
    .center-box {
      display: grid;
      place-items: center;
      height: 100vh;
    }
  `,
})
export class AboutComponent {
  version = wfaVersion
}
