import Joi from 'joi'

import { newWfaMessage, WfaMessage } from '../types/wfa-message'
import { nullish } from './nullish'

type JoiSchema<T> =
  | Joi.ObjectSchema<T>
  | Joi.AlternativesSchema<T>
  | Joi.StringSchema

export function validate<T>(
  joiSchema: JoiSchema<T>,
  x: unknown,
): T | WfaMessage {
  if (nullish(x)) return newWfaMessage(`[WFA] validate: nullish input`)
  const { error } = joiSchema.validate(x)
  if (nullish(error)) return x as T
  return newWfaMessage(`[WFA] validate: ${error.message}`)
}

export function isValid<T>(joiSchema: JoiSchema<T>, x: unknown): x is T {
  if (nullish(x)) return false
  const { error } = joiSchema.validate(x)
  if (nullish(error)) return true
  return false
}

export function createVldUtils<T>(joiSchema: JoiSchema<T>) {
  return {
    isValid: (x: unknown) => isValid(joiSchema, x),
    validate: (x: unknown) => validate(joiSchema, x),
  }
}
