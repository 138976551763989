import { OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { AddressFormGroupTemplate, createAddressFormGroup } from "@a-d/forms/fields/create-address-form-group";
import { ADValidators } from "@a-d/forms/validators/a-d-validators";
import { dynamicallyRequired } from "@a-d/wfr/wfa/forms/form-validators";
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import dayjs from "dayjs";
import { PersonalFormGroup } from "./default-personal-form-group";
import { MaybeBirthDateControlNeeded } from "./entities";
import vld from "./validators";

/* mutates `formGroup` */
type AddressKeys = keyof AddressFormGroupTemplate
type AddressValues = AddressFormGroupTemplate[keyof AddressFormGroupTemplate]
type AddressEntries = [AddressKeys, AddressValues]
export function maybeAddAddressFormControl(
  addressConfig: OTKFieldConfig,
  formGroup: PersonalFormGroup,
  activeInstanceCountry: string
): void {
  if (addressConfig.display === true) {
    const addressControls = createAddressFormGroup(
      true,
      null,
      activeInstanceCountry,
      addressConfig.required,
      true,
      true
    ).controls;
    Object.entries(addressControls).forEach((entry) => {
      const [key, value] = entry as unknown as AddressEntries;
      formGroup.addControl(key, value);
    });
  }
}

// mutates personalFormGroup
export function maybeAddBirthDate(
  personalFormGroup: PersonalFormGroup,
  zollsoftMode: boolean,
  reducedForm = false
): PersonalFormGroup {
  if (reducedForm !== true && zollsoftMode !== true) {
    const birthDateControl = new UntypedFormControl("", [
      dynamicallyRequired,
      ADValidators.datesRelation("before", dayjs(), false),
    ]);
    personalFormGroup.addControl("birthDate", birthDateControl);
  }
  return personalFormGroup
}

/**
 * Create form control `config.identifier` in `formGroup`
 *
 * !!! mutates `formGroup`
 */
export function maybeAddFormControl(
  config: OTKFieldConfig | null,
  formGroup: UntypedFormGroup,
  validators: ValidatorFn[] = [],
  initValue: any = ""
): void {
  if (config == null) return;

  const useValidators = config.required
    ? [...validators, dynamicallyRequired]
    : validators;

  if (config.display) {
    formGroup.addControl(
      config.identifier,
      new UntypedFormControl(initValue, useValidators)
    );
  }
}

/**
 * Create form control `identifier` in `formGroup`
 *
 * !!! mutates `formGroup`
 */
export function maybeAddCheckboxControl(
  config: OTKFieldConfig | null,
  formGroup: UntypedFormGroup
): void {
  maybeAddFormControl(config, formGroup, [], false);
}

/**
 * Creates basic form controls except `address` and `birthDate`
 *
 * !!! mutates `formGroup`
 */
// prettier-ignore
export function addBasicFieldControls(
  mutatedFormGroup: UntypedFormGroup,
  basicFields: Record<string, OTKFieldConfig>,
  activeInstanceCountry: string,
  maybeBirthDateControlNeeded?: MaybeBirthDateControlNeeded
) {
  maybeAddFormControl(basicFields["gender"], mutatedFormGroup, [ADValidators.gender]);
  maybeAddFormControl(basicFields["phone"], mutatedFormGroup, vld.phoneValidator);
  maybeAddFormControl(basicFields["emailCC"], mutatedFormGroup, [Validators.email, Validators.pattern(/.+@.+\..+/)])
  maybeAddFormControl(basicFields["info"], mutatedFormGroup, [ADValidators.noLeadingOrTrailingWhitespace(), ...vld.defaultValidators(basicFields.info?.limit)]);
  maybeAddFormControl(basicFields["insuranceName"], mutatedFormGroup, [ADValidators.noLeadingOrTrailingWhitespace(), ...vld.defaultValidators(basicFields.insuranceName?.limit)]);
  maybeAddFormControl(basicFields["customerId"], mutatedFormGroup, [Validators.pattern(/^[0-9]\d*$/), ...vld.defaultValidators(basicFields.customerId?.limit)]);
  maybeAddFormControl(basicFields["insuranceNumber"], mutatedFormGroup, [ADValidators.noLeadingOrTrailingWhitespace(), ...vld.defaultValidators(basicFields.insuranceNumber?.limit)]);

  maybeAddCheckboxControl(basicFields["confirmTreated"], mutatedFormGroup);
  maybeAddCheckboxControl(basicFields["confirmCorrect"], mutatedFormGroup);
  maybeAddCheckboxControl(basicFields["checkbox3"], mutatedFormGroup);
  maybeAddCheckboxControl(basicFields["checkbox4"], mutatedFormGroup);
  maybeAddCheckboxControl(basicFields["checkbox5"], mutatedFormGroup);

  maybeAddAddressFormControl(basicFields["address"], mutatedFormGroup, activeInstanceCountry);

  if (maybeBirthDateControlNeeded != null) {
    const { zollsoftMode, reducedForm } = maybeBirthDateControlNeeded
    maybeAddBirthDate(mutatedFormGroup, zollsoftMode, reducedForm)
  }
}
