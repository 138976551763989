import { WfaForm } from '../../schemas/interfaces/wfa-form'
import { Maybe } from '../../types/maybe'
import { EditorEnv, initialEditorEnv } from './editor.env'

export interface EditorState extends Record<string, unknown> {
  editorEnv: EditorEnv
  form: Maybe<WfaForm>
  formIsValid?: boolean
}

export const initialEditorState: EditorState = {
  editorEnv: initialEditorEnv,
  form: undefined,
}
