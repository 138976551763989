import { get } from '../app/utility/lodash-alternatives/get'
import {
  SurveyJsElement,
  SurveyJsItem,
  WfaPage,
} from '../app/viewer/types/index'
import { MostRelaxedWfaForm } from '../schemas/interfaces/wfa-form'
import { WfaQuestion } from './../schemas/interfaces/wfa-question/wfa-question'
import { nullish } from './nullish'

export function getQuestions(wfaForm: MostRelaxedWfaForm): WfaQuestion[] {
  const wfaPages: WfaPage[] = get(wfaForm, 'pages')
  if (nullish(wfaPages)) return []

  return wfaPages.flatMap((page) => getPageQuestions(page))
}

const getPageQuestions = (wfaPage: WfaPage) => {
  return getSurveyItemQuestions(wfaPage)
}

export function getSurveyItemQuestions(
  surveyItem: SurveyJsItem,
): WfaQuestion[] {
  const elements: SurveyJsElement[] = get(surveyItem, 'elements')
  if (nullish(elements)) return []

  return elements.flatMap((element) => {
    if (elementIsPanel(element)) return getSurveyItemQuestions(element)
    return element
  })
}

export function elementIsPanel(element: SurveyJsElement): boolean {
  return element['type'] === 'panel'
}
