import { BehaviorSubject } from 'rxjs'

import {
  defaultEncryptString,
  EncryptString,
} from '../app/types/encrypt-string'
import { consoleLogger, Logger } from '../types/logger'
import { initialEditorEnv } from './../app/editor/editor.env'
import { EditorEnv } from './../app/editor/editor.env'
import { Maybe } from './../types/maybe'

interface InstanceDetails {
  identifier: string
}

export type TargetBuild = 'indes' | 'adis' | 'lotos'
export interface WfaEnv {
  instanceDetails?: Maybe<InstanceDetails>
  editorEnv$: BehaviorSubject<EditorEnv>
  encryptString: EncryptString
  frontendUrl: string
  frontendUrlSubdirectory: string
  logger: Logger
  production: boolean
  demoMode: boolean
  serverUrl: string
  serverUrlSubdirectory: string
  targetBuild: TargetBuild
}

export type AdisWfaEnv = WfaEnv & {
  targetBuild: 'adis'
}

export type LotosWfaEnv = WfaEnv & {
  targetBuild: 'lotos'
}

export type IndesWfaEnv = WfaEnv & {
  targetBuild: 'indes'
}

export const indesWfaEnv: WfaEnv = {
  demoMode: true,
  editorEnv$: new BehaviorSubject<EditorEnv>(initialEditorEnv),
  encryptString: defaultEncryptString,
  frontendUrl: 'http://localhost:4200',
  frontendUrlSubdirectory: '',
  logger: consoleLogger,
  production: false,
  serverUrl: 'http://localhost:8082',
  serverUrlSubdirectory: '',
  targetBuild: 'indes',
}

export const lotosWfaEnv: WfaEnv = {
  demoMode: false,
  editorEnv$: new BehaviorSubject<EditorEnv>(initialEditorEnv),
  encryptString: defaultEncryptString,
  frontendUrl: 'http://localhost:8080',
  frontendUrlSubdirectory: '/tomedo_live/static/wfa-lotos/index.html#',
  logger: consoleLogger,
  production: false,
  serverUrl: 'https://onlinetermine.test-arzt-direkt.zollsoft.net',
  serverUrlSubdirectory: '/api/wfb/app',
  targetBuild: 'lotos',
}

export function isAdisWfaEnv(x: unknown): x is AdisWfaEnv {
  return (x as WfaEnv).targetBuild === 'adis'
}

export function isLotosWfaEnv(x: unknown): x is LotosWfaEnv {
  return (x as WfaEnv).targetBuild === 'lotos'
}

export function isIndesWfaEnv(x: unknown): x is IndesWfaEnv {
  return (x as WfaEnv).targetBuild === 'indes'
}
