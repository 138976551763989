import { MatDialog } from '@angular/material/dialog'
import { SurveyModel } from 'survey-core'
import { SurveyCreatorModel } from 'survey-creator-core'

import { CoreLocale } from '../../../schemas/interfaces/core-locale'
import { ViewerEnvState } from '../../../schemas/interfaces/viewer-env-state'
import { customizeFileUploadQuestion } from '../../shared-surveyjs/file-upload-question-customization/customize-file-upload-question'
import { addInJsonValidators } from '../../shared-surveyjs/validation-customization/in-json-validation/add-in-json-validators'
import { addOnEventValidators } from '../../shared-surveyjs/validation-customization/on-event-validation/add-on-event-validators'

export function customizePreview(
  creator: SurveyCreatorModel,
  dialog: MatDialog,
): void {
  creator.onSurveyInstanceCreated.add((_, options) => {
    if (options.area === 'preview-tab') {
      const surveyModel = options.survey
      const env: ViewerEnvState = {
        displayOption: 'editable',
        isEmbeddedIn: 'adStepper',
        locale: options.survey.locale as CoreLocale,
      }
      customizeValidation(surveyModel)
      customizeFileUploadQuestion(surveyModel, dialog, env)
    }
  })
}

function customizeValidation(surveyModel: SurveyModel): void {
  addInJsonValidators()
  addOnEventValidators(surveyModel)
}
