import {
  HTML as SurveyjsHTMLQuestion,
  SurveyjsForm,
} from '../test/surveyjs-form/surveyjs.form'
import { nullish } from '../utility/nullish'

export interface WfaMessage {
  type: 'wfaMessage'
  message: string
}

export function isWfaMessage<T>(x: T | WfaMessage): x is WfaMessage {
  if (nullish(x)) return false
  return (
    (x as WfaMessage).type === 'wfaMessage' &&
    typeof (x as WfaMessage).message === 'string'
  )
}

export function newWfaMessage(message: string): WfaMessage {
  return {
    type: 'wfaMessage',
    message,
  }
}

export function printWfaMessage(w: WfaMessage): void {
  console.log(w.message)
}

export function convertWfaMessageToSurveyjsJson(w: WfaMessage): SurveyjsForm {
  return {
    title: 'internal error',
    pages: [
      {
        name: 'page1',
        elements: [
          {
            type: 'html',
            name: 'question1',
            html: w.message,
          } as SurveyjsHTMLQuestion,
        ],
      },
    ],
  }
}

export function explain(w: WfaMessage, details: string): WfaMessage {
  return {
    type: 'wfaMessage',
    message: `${details}. ${w.message}`,
  }
}

export function explainMissingParameter(
  w: WfaMessage,
  funcName: string,
  paramName: string,
  params: Record<string, unknown>,
): WfaMessage {
  const stringParams = JSON.stringify(params)

  return explain(
    w,
    `${funcName}: Parameter ${paramName} is missing in ${stringParams}`,
  )
}
