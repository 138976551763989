import { localization } from 'survey-creator-core'

import { coreLocales } from '../../../../schemas/joi/core-locale.joi'
import { notNullish } from '../../../../utility/nullish'
import { customizeTranslations as customizePreviewTranslations } from '../../../shared-surveyjs/translation-customization/customize-translations'
import { getLocalizedTranslations } from '../../../utility/get-localized-translations'
import { mergeDeep } from '../../../utility/lodash-alternatives/merge-deep'
import { customTranslations } from './custom-translations/_custom-translations'
import { renameTerms } from './rename-terms'

export function customizeTranslations(): void {
  customizeCreatorTranslations()
  customizePreviewTranslations()
}

function customizeCreatorTranslations(): void {
  coreLocales.forEach((locale) => {
    const translations = localization.getLocale(locale)
    const localizedTranslations = getLocalizedTranslations(
      customTranslations,
      locale,
    )
    if (notNullish(translations) && notNullish(localizedTranslations)) {
      renameTerms(translations, locale)
      mergeDeep(translations, localizedTranslations)
    }
  })
}
