import { CoreLocale } from '../../../../schemas/interfaces/core-locale'
import { WfaForm } from '../../../../schemas/interfaces/wfa-form'
import { initialViewerState } from '../../viewer.state'

const fallbackLocale = initialViewerState.env.locale

export function getFormTitle(
  wfaForm: WfaForm,
  locale: CoreLocale = fallbackLocale,
): string {
  return wfaForm.title[locale] ?? (wfaForm.title[fallbackLocale] as string)
}
