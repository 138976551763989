import { nullish } from '../../../../../utility/nullish'

/**
 * Based on the validation in arzt-direkt (`kassennummerValidator`)
 * @see `arzt-direkt/projects/arzt-direkt/src/app/forms/form-validators.service.ts`
 */
export function isValidInsuranceNumber([input]: (string | number)[]): boolean {
  if (nullish(input)) return true
  if (typeof input === 'number') input = String(input)
  if (input.length !== 9) return false
  if (!hasOnlyDigits(input)) return false

  const checksum = calculateChecksum(input)
  return validateWithCheckDigit(input, checksum)
}

function hasOnlyDigits(input: string): boolean {
  const regex = /^\d+$/
  return regex.test(input)
}

function calculateChecksum(input: string): number {
  const digits = input.substring(2, 8).split('')
  let sum = 0
  digits.forEach((digit, index) => {
    const factor = index % 2 == 0 ? 2 : 1
    const multiplication = parseInt(digit) * factor
    const digitSum = calculateDigitSum(multiplication)
    sum += digitSum
  })
  return sum
}

function calculateDigitSum(multiplication: number): number {
  const absoluteValue = Math.abs(multiplication)
  const digits = absoluteValue.toString().split('')
  return digits.reduce(function (a, b) {
    return +a + +b
  }, 0)
}

function validateWithCheckDigit(input: string, checksum: number): boolean {
  const checkDigit = checksum % 10
  const lastDigit = input.split('').pop()
  if (nullish(lastDigit)) return false
  const givenCheckDigit = parseInt(lastDigit)
  if (givenCheckDigit !== checkDigit) return false
  return true
}
