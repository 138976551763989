<div attr.data-selenium-id="div-booking-personal" #bookingPersonal>
  <form attr.data-selenium-id="form-booking-personal" class="c-form--fullHeight" [formGroup]="formGroup"
    id="otk-scrollable-content--booking-personal" class="booking-personal">
    <div attr.data-selenium-id="div-customer-id" class="c-form__row">
      <!-- Contact Basic Config Show: customerId -->
      <ng-container *ngIf="basicFields?.customerId?.display && formGroup.get('customerId')">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'OTK.BOOKING-PERSONAL.customerId' | translate }}
            <span *ngIf="basicFields?.customerId?.required" class="isError">*</span>
          </mat-label>
          <input attr.data-selenium-id="input-customer-id" matInput
            [formControlName]="basicFields?.customerId?.identifier" [name]="basicFields?.customerId?.identifier"
            spellcheck="false" />
          <mat-error *ngIf="formGroup.get('customerId').hasError('pattern')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.CUSTOMER-PATTERN' | translate">
          </mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <!-- Always show: name -->
    <div attr.data-selenium-id="div-fname" class="c-form__row">
      <mat-form-field attr.data-selenium-id="mat-form-field-fname" class="c-form__row__item" appearance="outline">
        <mat-label>
          {{ (pediatricianMode ? 'PERSONAL-FORM.PF-NAME' : 'PERSONAL-FORM.F-NAME') | translate }}
          <span class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-fname" matInput formControlName="fname" spellcheck="false" name="fname"
          autocomplete="given-name" />
        <mat-error *ngIf="formGroup.get('fname').hasError('typeError')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.TYPE' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('fname').hasError('notAllowedCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPEC-CHARS' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('fname').hasError('leadingOrTrailingWhitespace')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPACE' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('fname').hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('fname').hasError('maxlength')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.LENGTH' | translate">
        </mat-error>
      </mat-form-field>

      <mat-form-field attr.data-selenium-id="mat-form-field-lname" class="c-form__row__item" appearance="outline">
        <mat-label>
          {{ (pediatricianMode ? 'PERSONAL-FORM.PL-NAME' : 'PERSONAL-FORM.L-NAME') | translate }}
          <span class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-lname" matInput formControlName="lname" spellcheck="false" name="lname"
          autocomplete="family-name" />
        <mat-error *ngIf="formGroup.get('lname').hasError('typeError')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.TYPE' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('lname').hasError('notAllowedCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPEC-CHARS' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('lname').hasError('leadingOrTrailingWhitespace')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPACE' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('lname').hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('lname').hasError('maxlength')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.LENGTH' | translate">
        </mat-error>
      </mat-form-field>
    </div>

    <div attr.data-selenium-id="div-name-affix" class="c-form__row" *ngIf="showNameTitleAndAffix">
      <app-name-title-field [formGroup]="formGroup" [label]="'PERSONAL-FORM.NAME-TITLE' | translate" name="nameTitle">
      </app-name-title-field>

      <mat-form-field attr.data-selenium-id="mat-form-field-affix" class="c-form__row__item--verySmall"
        appearance="outline">
        <mat-label>{{
          'PERSONAL-FORM.NAME-TITLE-PLACEHOLDER' | translate
          }}</mat-label>
        <input attr.data-selenium-id="input-name-affix" matInput formControlName="nameAffix" spellcheck="false"
          autocomplete="chrome-off" />
      </mat-form-field>
    </div>

    <div attr.data-selenium-id="div-gender" class="c-form__row">
      <!-- Basic Config Show: gender -->
      <ng-container *ngIf="basicFields?.gender?.display">
        <mat-form-field attr.data-selenium-id="mat-form-field-gender" class="c-form__row__item--small"
          appearance="outline">
          <mat-label>
            {{ 'PERSONAL-FORM.GENDER' | translate }}
            <span *ngIf="basicFields?.gender?.required" class="isError">*</span>
          </mat-label>
          <mat-select attr.data-selenium-id="mat-select-gender" formControlName="gender" #gender>
            <mat-select-trigger>
              <span *ngIf="gender.value === 'M'">{{ 'PERSONAL-FORM.GENDER-MALE' | translate }}</span>
              <span *ngIf="gender.value === 'W'">{{ 'PERSONAL-FORM.GENDER-FEMALE' | translate }}</span>
              <span *ngIf="gender.value === 'D'">{{ 'PERSONAL-FORM.GENDER-OTHER' | translate }}</span>
            </mat-select-trigger>
            <mat-option attr.data-selenium-id="mat-option-gender-m" value="M"
              [innerHTML]="'PERSONAL-FORM.GENDER-MALE' | translate"></mat-option>
            <mat-option attr.data-selenium-id="mat-option-gender-w" value="W"
              [innerHTML]="'PERSONAL-FORM.GENDER-FEMALE' | translate"></mat-option>
            <mat-option attr.data-selenium-id="mat-option-gender-d" value="D"
              [innerHTML]="'PERSONAL-FORM.GENDER-OTHER' | translate"></mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- Always show: birth date-->
      <mat-form-field attr.data-selenium-id="mat-form-field-birth-date" *ngIf="!isZollsoftMode"
        class="c-form__row__item--small" appearance="outline">
        <mat-label>
          {{ (pediatricianMode ? 'PERSONAL-FORM.PBIRTHDAY' : 'PERSONAL-FORM.BIRTHDAY') | translate }}
          <span class="isError">*</span>
        </mat-label>
        <ng-container *ngIf="presetBirthDate">
          <input attr.data-selenium-id="input-birthdate" matInput [matDatepicker]="picker"
            [matDatepickerFilter]="MIN_AGE_FILTER" [placeholder]="'GLOBAL.DATE-PLACEHOLDER' | translate"
            formControlName="birthDate" name="bday" autocomplete="bday" #birthDayInput [min]="MIN_BIRTH_DATE"
            readonly />
          <mat-datepicker attr.data-selenium-id="mat-datepicker-birthdate" #picker startView="multi-year"
            [startAt]="birthDateStart" xPosition="end" yPosition="below">
          </mat-datepicker>
        </ng-container>
        <ng-container *ngIf="!presetBirthDate">
          <input attr.data-selenium-id="input-birthdate" matInput [matDatepicker]="picker"
            [matDatepickerFilter]="MIN_AGE_FILTER" [placeholder]="'GLOBAL.DATE-PLACEHOLDER' | translate"
            formControlName="birthDate" name="bday" autocomplete="bday" #birthDayInput [min]="MIN_BIRTH_DATE"
            (click)="picker.open()" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker attr.data-selenium-id="mat-datepicker-birthdate" #picker startView="multi-year"
            [startAt]="birthDateStart" xPosition="end" yPosition="below">
          </mat-datepicker>
        </ng-container>

        <mat-error *ngIf="
            formGroup.get('birthDate').errors &&
            formGroup.get('birthDate').errors['datesInvalid']
          " [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.DATE-INVALID' | translate"></mat-error>
        <mat-error *ngIf="
            formGroup.get('birthDate').errors &&
            formGroup.get('birthDate').errors['dateIsNotBeforeOrSame']
          " [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.DATE-PAST' | translate"></mat-error>
        <mat-hint *ngIf="formGroup.get('birthDate').invalid &&
            (!formGroup.get('birthDate').errors ||
              !formGroup.get('birthDate').errors.length)
          " [innerHTML]="'GLOBAL.DATE-PLACEHOLDER' | translate"></mat-hint>
      </mat-form-field>
    </div>

    <div attr.data-selenium-id="div-email" class="c-form__row">
      <!-- Always show: email -->
      <mat-form-field attr.data-selenium-id="mat-form-field-email" appearance="outline">
        <mat-label>
          {{ 'PERSONAL-FORM.EMAIL-PLACEHOLDER' | translate }}
          <span class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-email" matInput formControlName="email" spellcheck="false"
          autocomplete="email" name="email" type="email" />
        <mat-error>
          {{'PERSONAL-FORM.EMAIL-ERROR' | translate}}
        </mat-error>
        <mat-error *ngIf="formGroup.get('email').hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
      </mat-form-field>

      <!-- Always show: confirm email -->
      <mat-form-field attr.data-selenium-id="mat-form-field-email-confirm" appearance="outline">
        <mat-label>
          {{ 'PERSONAL-FORM.EMAIL-CONFIRM-PLACEHOLDER' | translate }}
          <span class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-email-confirm" matInput formControlName="emailConfirm" spellcheck="false"
          autocomplete="emailConfirm" name="emailConfirm" type="email" />
        <mat-error>
          {{'OTK.BOOKING-PERSONAL.ERRORS.EMAIL-CONFIRM' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- Basic Config show: emailCC -->
      <ng-container *ngIf="basicFields?.emailCC?.display">
        <mat-form-field attr.data-selenium-id="mat-form-field-email-cc" appearance="outline">
          <mat-label>{{ basicFields?.emailCC?.description | i18nString:'OTK.BOOKING-PERSONAL.emailCC' |
            translate }}
            <span *ngIf="basicFields?.emailCC?.required" class="isError">*</span>
          </mat-label>
          <input attr.data-selenium-id="input-email" matInput [formControlName]="basicFields?.emailCC.identifier"
            spellcheck="false" autocomplete="email" name="emailCC" type="email" />
          <mat-error>
            {{'PERSONAL-FORM.EMAIL-ERROR' | translate}}
          </mat-error>
          <mat-error *ngIf="formGroup.get('emailCC').hasError('specialCharacters')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
          </mat-error>
        </mat-form-field>
      </ng-container>

      <!-- Basic Config Show: phone -->
      <ng-container *ngIf="basicFields?.phone?.display">
        <mat-form-field attr.data-selenium-id="mat-form-field-phone" appearance="outline">
          <mat-label>
            {{ 'PERSONAL-FORM.PHONE-PLACEHOLDER' | translate }}
            <span *ngIf="basicFields?.phone?.required" class="isError">*</span>
          </mat-label>
          <input attr.data-selenium-id="input-phone" matInput [formControlName]="basicFields?.phone?.identifier"
            [name]="basicFields?.phone?.identifier" autocomplete="phone" spellcheck="false" />
          <!-- the optional chaining here after get('phone') is there to try to solve this https://zollsoft.atlassian.net/browse/ADI-2302 -->
          <mat-error *ngIf="formGroup.get('phone')?.hasError('pattern')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPEC-CHARS' | translate">
          </mat-error>
          <mat-error *ngIf="formGroup.get('phone')?.hasError('maxlength')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.LENGTH' | translate">
          </mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <!-- Basic Config Show: address -->
    <div attr.data-selenium-id="div-address-search-field" class="c-form__row"
      *ngIf="basicFields?.address?.display && addressVisible">
      <app-address-search-field custom="true" [formGroup]="formGroup" [seperateStreetNameAndNumber]="true"
        [stepInteracted]="false" [customAddressLabel]="'PERSONAL-FORM.ADDRESS-LABEL' | translate"
        [useDynamicallyRequiredValidator]="basicFields?.address.required"
        [requireCountry]="basicFields?.address.required" #addressSearchField></app-address-search-field>
    </div>

    <!-- Basic Config Show: insurance name -->
    <div attr.data-selenium-id="div-insurance" class="c-form__row">
      <ng-container *ngIf="basicFields?.insuranceName?.display">
        <mat-form-field attr.data-selenium-id="mat-form-field-insurance" appearance="outline">
          <mat-label>{{
            'OTK.BOOKING-PERSONAL.insuranceName' | translate
            }}
            <span *ngIf="basicFields?.insuranceName?.required" class="isError">*</span>
          </mat-label>
          <input attr.data-selenium-id="input-insurance-name" matInput
            [formControlName]="basicFields?.insuranceName?.identifier" [name]="basicFields?.insuranceName?.identifier"
            autocomplete="insuranceName" spellcheck="false" />
          <mat-error *ngIf="formGroup.get('insuranceName').invalid" [ngSwitch]="getErrorKey('insuranceName')">
            <ng-container *ngSwitchCase="'maxlength'">
              {{ 'OTK.BOOKING-PERSONAL.ERRORS.LENGTH' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'leadingOrTrailingWhitespace'">
              {{ 'OTK.BOOKING-PERSONAL.ERRORS.SPACE' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'specialCharacters'">
              {{ 'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate }}
            </ng-container>
          </mat-error>

        </mat-form-field>
      </ng-container>

      <!-- insurance number -->
      <ng-container *ngIf="basicFields?.insuranceNumber?.display">
        <mat-form-field attr.data-selenium-id="mat-form-field-insurance-number" appearance="outline">
          <mat-label>{{
            'OTK.BOOKING-PERSONAL.insuranceNumber' | translate
            }}
            <span *ngIf="basicFields?.insuranceNumber?.required" class="isError">*</span>
          </mat-label>
          <input attr.data-selenium-id="input-insurance-number" matInput
            [formControlName]="basicFields?.insuranceNumber?.identifier"
            [name]="basicFields?.insuranceNumber?.identifier" autocomplete="insuranceNumber" spellcheck="false" />
          <mat-error *ngIf="formGroup.get('insuranceNumber').hasError('leadingOrTrailingWhitespace')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPACE' | translate">
          </mat-error>
          <mat-error *ngIf="formGroup.get('insuranceNumber').hasError('tooLong')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.PERSONAL-FORM.LENGTH' | translate">
          </mat-error>
          <mat-error *ngIf="formGroup.get('insuranceNumber').hasError('specialCharacters')"
            [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
          </mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <ng-container *ngIf="isContactFormSelected">
      <div *ngIf="basicFields?.issue?.display" class="c-form__row">
        <!-- Contact Basic Config Show: issue -->
        <app-selection-field-component [descriptionRequiredIfSonstiges]="true" [formGroup]="formGroup"
          [multiple]="false" [options]="contactFormIssues"
          [selectionPlaceholder]="'OTK.BOOKING-PERSONAL.issue' | translate" [selectionRequired]="true"
          [selectionPretendRequired]="false" [sonstigesOption]="true" [withTypeahead]="true"
          [selectionControl]="formGroup.get('issue')" selectionName="'issue'" class="c-form__row__item--small">
        </app-selection-field-component>
      </div>
    </ng-container>

    <!-- Basic Config Show: info -->
    <div attr.data-selenium-id="div-info" class="c-form__row" *ngIf="basicFields?.info?.display">
      <mat-form-field appearance="outline">
        <mat-label>{{ basicFields?.info?.description | i18nString:'OTK.BOOKING-PERSONAL.info-description' |
          translate }}
          <span *ngIf="basicFields?.info?.required" class="isError">*</span>
        </mat-label>
        <textarea attr.data-selenium-id="textarea-basic-fieldidentifier" matInput cdkTextareaAutosize
          [formControlName]="basicFields?.info?.identifier" [name]="basicFields?.info?.identifier"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" spellcheck="false"
          [maxlength]="basicFields?.info?.limit ?? stdCharLimit" class="mobile-scrollbar"></textarea>
        <span class="mat-hint custom-mat-hint">
          {{formGroup.get("info").value?.length ?? '0'}} / {{basicFields?.info?.limit ?? stdCharLimit}}
        </span>
        <mat-error *ngIf="formGroup.get('info').hasError('leadingOrTrailingWhitespace')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPACE' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('info').hasError('tooLong')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.LENGTH' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('info').hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
      </mat-form-field>
    </div>

    <!-- custom fields -->
    <ng-container class="c-form__row" *ngIf="customFields.length !== 0">
      <div class="c-form__row" *ngFor="let customField of customFields;let idx=index;">
        <ng-container *ngIf="customField.display">
          <mat-form-field appearance="outline">
            <mat-label>{{ customField.description | i18nString:'OTK.BOOKING-PERSONAL.customField' |
              translate:{count: idx+1} }}
              <span *ngIf="customField.required" class="isError">*</span>
            </mat-label>
            <textarea attr.data-selenium-id="textarea-custom-field-identifier" matInput cdkTextareaAutosize
              [formControlName]="customField.identifier" [name]="customField.identifier" mat-autosize="true"
              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" spellcheck="false"
              [maxlength]="customField?.limit ?? stdCharLimit"></textarea>
            <span class="mat-hint custom-mat-hint">
              {{formGroup.get(customField.identifier).value?.length}} / {{customField?.limit ?? stdCharLimit}}
            </span>
            <mat-error *ngIf="
                formGroup.get(customField.identifier).hasError('leadingOrTrailingWhitespace')
              " [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.SPACE' | translate">
            </mat-error>
            <mat-error *ngIf="formGroup.get(customField.identifier).hasError('tooLong')"
              [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.LENGTH' | translate">
            </mat-error>
            <mat-error *ngIf="formGroup.get(customField.identifier).hasError('specialCharacters')"
              [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
            </mat-error>

          </mat-form-field>
        </ng-container>
      </div>
    </ng-container>

    <!--Asset Upload -->
    <app-booking-file-upload *ngIf="showFileUpload"></app-booking-file-upload>

    <!-- Checkboxes -->
    <app-checkbox attr.data-selenium-id="app-checkbox-confirm-treated" [control]="formGroup.get('confirmTreated')"
      [config]="basicFields?.confirmTreated">
    </app-checkbox>
    <app-checkbox attr.data-selenium-id="app-checkbox-confirm-correct" [control]="formGroup.get('confirmCorrect')"
      [config]="basicFields?.confirmCorrect">
    </app-checkbox>
    <app-checkbox attr.data-selenium-id="app-checkbox-3" [control]="formGroup.get('checkbox3')"
      [config]="basicFields?.checkbox3"></app-checkbox>
    <app-checkbox attr.data-selenium-id="app-checkbox-4" [control]="formGroup.get('checkbox4')"
      [config]="basicFields?.checkbox4"></app-checkbox>
    <app-checkbox attr.data-selenium-id="app-checkbox-5" [control]="formGroup.get('checkbox5')"
      [config]="basicFields?.checkbox5"></app-checkbox>

    <!--- Datenschutzerklärung-->
    <div>
      <div class="c-form__row">
        <mat-checkbox attr.data-selenium-id="mat-checkbox-privacy-policy" formControlName="privacyPolicy" required>
          <strong>
            <span (click)="clickToDataProtection($event)"
              [innerHTML]="'PERSONAL-FORM.PRIVACY-POLICY' | translate | sanitizeHtml"></span>
            <span class="isError">*</span>
          </strong>
          <mat-error
            *ngIf="formGroup.controls.privacyPolicy?.errors?.required && !formGroup.controls.privacyPolicy?.untouched">
            {{ 'OTK.BOOKING-PERSONAL.ERRORS.CHECK-BOX' |
            translate }}
          </mat-error>
        </mat-checkbox>
      </div>
    </div>
  </form>

  <!-- appointment type info -->
  <div *ngIf="(info | i18nString:'') && showInfo" class="info">
    <mat-icon>info</mat-icon>
    <span class="infoHtml" [innerHtml]="info | i18nString:''"></span>
  </div>
</div>