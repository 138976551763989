import { IJsonPropertyInfo, Serializer } from 'survey-core'
import { PropertyGridEditorCollection } from 'survey-creator-core'

import { CustomPropertyInfo } from '../../../../schemas/interfaces/custom-property-info'
import { notNullish } from '../../../../utility/nullish'
import { Dictionary } from '../../../types/dictionary'
import {
  customEditorProperties,
  customViewerProperties,
} from './_custom-properties.barrel'

function addCustomProperties(
  customPropertiesDictionary: Dictionary<CustomPropertyInfo[]>,
): void {
  Object.entries(customPropertiesDictionary).forEach(
    ([surveyjsClass, propertiesInfo]) => {
      Serializer.addProperties(
        surveyjsClass,
        propertiesInfo as IJsonPropertyInfo[],
      )
      customizePropertyDisplaySettings(propertiesInfo)
    },
  )
}

function customizePropertyDisplaySettings(
  propertiesInfo: CustomPropertyInfo[],
) {
  propertiesInfo.forEach((propertyInfo) => {
    const displaySettings = propertyInfo.editorDisplaySettings
    if (notNullish(displaySettings))
      PropertyGridEditorCollection.register(displaySettings)
  })
}

export const addCustomEditorProperties = () =>
  addCustomProperties(customEditorProperties)

export const addCustomViewerProperties = () =>
  addCustomProperties(customViewerProperties)
