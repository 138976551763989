import { SurveyjsPropertyInfo } from '../../../../../schemas/interfaces'

export const checkErrorsMode: SurveyjsPropertyInfo = {
  name: 'checkErrorsMode',
  surveyElements: ['survey'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: 'onValueChanged',
    },
  ],
}
