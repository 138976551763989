import Joi from 'joi'

import { IsEmbeddedIn } from '../interfaces'
import { TargetBuild } from './../../environments/wfa-env'

export const isEmbeddedIns = [
  'arztDirekt',
  'iOsTomedo',
  'macOsTomedo',
  'adStepper',
] as const

export const IsEmbeddedInJoi = Joi.string()
  .valid(...isEmbeddedIns)
  .meta({
    className: 'IsEmbeddedIn',
  })

const conversionDict: Record<TargetBuild, IsEmbeddedIn> = {
  indes: 'arztDirekt',
  adis: 'arztDirekt',
  lotos: 'iOsTomedo',
}

export function getIsEmbeddedIn(targetBuild: TargetBuild): IsEmbeddedIn {
  return conversionDict[targetBuild]
}
