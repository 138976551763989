import { Maybe } from '../types/maybe'

export function nullish<T>(x: Maybe<T>): x is null | undefined {
  if (x === null) return true
  if (x === undefined) return true
  return false
}

export function notNullish<T>(x: Maybe<T>): x is T {
  if (x === null) return false
  if (x === undefined) return false
  return true
}

type WithMaybeProp<K extends string, V> = { [Key in K]: Maybe<V> }
type WithProp<K extends string, V> = { [Key in K]: V }

export function notNullishProp<K extends string, V>(
  x: WithMaybeProp<K, V>,
  key: K,
): x is WithProp<K, V> {
  return notNullish(x[key])
}
