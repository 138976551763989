import { safeStructuredClone } from './lodash-alternatives/safe-structured-clone'
import { set } from './lodash-alternatives/set'

export type Assigner<State> = (state: State, value: unknown) => State

export function assign<State extends Record<string, unknown>>(
  path: string,
): Assigner<State> {
  return (object: State, value: unknown) =>
    set(safeStructuredClone(object), path, value) as State
}
