import { QuestionFileModel } from 'survey-core'
import { IQuestion, LocalizableString, Question } from 'survey-core'
import { DocController, SurveyPDF } from 'survey-pdf'
import {
  CompositeBrick,
  FlatFile,
  FlatQuestion,
  IPdfBrick,
  IPoint,
  IRect,
  ISize,
  LinkBrick,
  SurveyHelper,
  TextBrick,
} from 'survey-pdf'

import { Maybe } from '../../../../types/maybe'
import { notNullish } from '../../../../utility/nullish'

interface LowerLevelBrick {
  color: Maybe<string>
  link: Maybe<string>
  text: Maybe<string>
  textColor: Maybe<string>
}

interface UpperLevelBrick {
  bricks: LowerLevelBrick[]
  [x: string]: unknown
}

/**
 * This custom class is used to determine how the file upload question is displayed in the PDF export.
 * It was created based on {@link https://surveyjs.answerdesk.io/ticket/details/t16417/listing-of-uploaded-files-and-url-in-file-name | this SurveyJS support ticket}.
 * The basic structure is created using pdf bricks such as `LinkBrick`, `TextBrick` or `ImageBrick`.
 * More information on pdf bricks can be found {@link https://surveyjs.io/pdf-generator/documentation/customize-survey-question-rendering-in-pdf-form#custom-rendering | here}.
 */
export class FlatFileCustom extends FlatQuestion {
  public static readonly IMAGE_GAP_SCALE: number = 0.195
  public static readonly TEXT_MIN_SCALE: number = 5.0
  public static DEFAULT_IMAGE_FIT = 'contain'
  protected override question: QuestionFileModel

  public constructor(
    protected override survey: SurveyPDF,
    question: QuestionFileModel,
    controller: DocController,
  ) {
    super(survey, question, controller)
    this.question = question as QuestionFileModel
  }

  /**
   * Creates a composite pdf brick for an uploaded file.
   */
  private async generateFlatItem(
    point: IPoint,
    item: {
      name: string
      type: string
      content: string
      imageSize?: ISize
    },
  ): Promise<IPdfBrick> {
    /**
     * Create LinkBricks for the name of an uploaded file.
     */
    const iPdfBrick = await this.createLinkFlat(
      point,
      this.question,
      this.controller,
      item.name === undefined ? 'image' : item.name,
      item.content,
    )
    /**
     * Remove URL from LinkBricks.
     */
    ;(iPdfBrick as unknown as UpperLevelBrick).bricks.forEach(
      (brick, index) => {
        if (notNullish(brick.link)) {
          brick.link = null
          brick.textColor = '#404040'
          brick.text = index === 0 ? `\u2022 ${brick.text}` : brick.text
        }
        if (notNullish(brick.color)) brick.color = null
      },
    )

    const compositeFlat: CompositeBrick = new CompositeBrick(iPdfBrick)

    /**
     * Commented out temporarily due to WebView issues
     * See https://zollsoft.atlassian.net/browse/ADI-2539
     * If the uploaded file is an image, create and add an ImageBrick.
     */
    /*
    if (SurveyHelper.canPreviewImage(this.question, item, item.content)) {
      const imagePoint: IPoint = SurveyHelper.createPoint(compositeFlat)
      imagePoint.yTop += this.controller.unitHeight * FlatFile.IMAGE_GAP_SCALE
      const iPdfBrick = await SurveyHelper.createImageFlat(
        imagePoint,
        this.question,
        this.controller,
        {
          link: item.content,
          width: item.imageSize?.width as number,
          height: item.imageSize?.height as number,
          objectFit: FlatFile.DEFAULT_IMAGE_FIT,
        }
      )
      compositeFlat.addBrick(iPdfBrick)
    } */
    return compositeFlat
  }

  /**
   * Creates a RowLineBrick (for line breaks).
   */
  private addLine(
    rowsFlats: CompositeBrick[],
    currPoint: IPoint,
    index: number,
  ): void {
    if (index !== this.question.previewValue.length - 1) {
      rowsFlats[rowsFlats.length - 1].addBrick(
        SurveyHelper.createRowlineFlat(currPoint, this.controller),
      )
      currPoint.yTop += SurveyHelper.EPSILON
      rowsFlats.push(new CompositeBrick())
    }
  }

  private async getImagePreviewContentWidth(item: {
    name: string
    type: string
    content: string
    imageSize?: ISize
  }) {
    return Math.max(
      item.imageSize?.width as number,
      FlatFile.TEXT_MIN_SCALE * this.controller.unitWidth,
    )
  }

  /**
   * Creates the content displayed in the pdf for the file upload question.
   */
  public override async generateFlatsContent(
    point: IPoint,
  ): Promise<IPdfBrick[]> {
    /**
     * Creates pdf bricks if no files were uploaded
     */
    if (this.question.previewValue.length === 0) {
      return [
        await SurveyHelper.createTextFlat(
          point,
          this.question,
          this.controller,
          this.question.noFileChosenCaption,
          TextBrick,
        ),
      ]
    }
    const rowsFlats: CompositeBrick[] = [new CompositeBrick()]
    const currPoint: IPoint = SurveyHelper.clone(point)
    let yBot: number = currPoint.yTop
    /**
     * Creates pdf bricks if files were uploaded
     */
    for (let i = 0; i < this.question.previewValue.length; i++) {
      const item: {
        name: string
        type: string
        content: string
        imageSize?: ISize
      } = { ...this.question.previewValue[i] }
      const canPreviewImage = SurveyHelper.canPreviewImage(
        this.question,
        item,
        item.content,
      )
      if (canPreviewImage) {
        item.imageSize = await SurveyHelper.getCorrectedImageSize(
          this.controller,
          {
            imageWidth: this.question.imageWidth,
            imageHeight: this.question.imageHeight,
            imageLink: this.question.previewValue[i].content,
          },
        )
      }

      if (canPreviewImage) {
        const compositeWidth = await this.getImagePreviewContentWidth(item)
        currPoint.xLeft = point.xLeft
        currPoint.yTop = yBot
        this.addLine(rowsFlats, currPoint, i)
        this.controller.pushMargins(
          currPoint.xLeft,
          this.controller.paperWidth - currPoint.xLeft - compositeWidth,
        )
        const itemFlat: IPdfBrick = await this.generateFlatItem(currPoint, item)
        rowsFlats[rowsFlats.length - 1].addBrick(itemFlat)
        currPoint.yTop += itemFlat.height
        currPoint.xLeft = point.xLeft
        yBot = Math.max(yBot, itemFlat.yBot)
        this.controller.popMargins()
      } else {
        currPoint.xLeft = point.xLeft
        currPoint.yTop = yBot
        this.addLine(rowsFlats, currPoint, i)
        const itemFlat: IPdfBrick = await this.generateFlatItem(currPoint, item)
        rowsFlats[rowsFlats.length - 1].addBrick(itemFlat)
        currPoint.xLeft += itemFlat.xRight - itemFlat.xLeft
        yBot = Math.max(yBot, itemFlat.yBot)
      }
    }
    return rowsFlats
  }

  /**
   * The functions listed below are modified `SurveyHelper` functions.
   * Original `SurveyHelper` functions can be found {@link https://github.com/surveyjs/survey-pdf/blob/master/src/helper_survey.ts | here}.
   */

  /**
   * Creates LinkBricks for a given text and returns them in the form of a composite pdf brick.
   */
  public async createLinkFlat(
    point: IPoint,
    question: Question,
    controller: DocController,
    text: string,
    link: string,
  ): Promise<IPdfBrick> {
    const compositeText: CompositeBrick = (await this.createTextFlat(
      point,
      question,
      controller,
      text,
      TextBrick,
    )) as CompositeBrick
    const compositeLink: CompositeBrick = new CompositeBrick()
    compositeText.unfold().forEach((text: IPdfBrick) => {
      compositeLink.addBrick(new LinkBrick(text as TextBrick, link))
      const linePoint: IPoint = SurveyHelper.createPoint(compositeLink)
      compositeLink.addBrick(
        SurveyHelper.createRowlineFlat(
          linePoint,
          controller,
          compositeLink.width,
          LinkBrick.COLOR,
        ),
      )
    })
    return compositeLink
  }

  /**
   * Creates TextBricks for a given text and returns them in the form of a composite pdf brick.
   */
  public async createTextFlat<T extends IPdfBrick>(
    point: IPoint,
    question: IQuestion,
    controller: DocController,
    text: string | LocalizableString,
    fabric: new (
      question: IQuestion,
      controller: DocController,
      rect: IRect,
      text: string,
    ) => T,
  ): Promise<IPdfBrick> {
    return this.createPlainTextFlat(
      point,
      question,
      controller,
      typeof text === 'string'
        ? text
        : SurveyHelper.getLocString(text as LocalizableString),
      fabric,
    )
  }

  /**
   * Creates plain text bricks for a given text and returns them in the form of a composite pdf brick.
   * Depending on the page format and margins, a decision is made here as to how many lines the text must be divided into.
   * In the original function, there were problems with image file names:
   * the length of a line was not based on the width of the page, but on the width of the image.
   * This is why the constant `marginRightForDinA4` was introduced.
   */
  public createPlainTextFlat<T extends IPdfBrick>(
    point: IPoint,
    question: IQuestion,
    controller: DocController,
    text: string,
    fabric: new (
      question: IQuestion,
      controller: DocController,
      rect: IRect,
      text: string,
    ) => T,
  ): CompositeBrick {
    const marginRightForDinA4 = 56.7
    const size = controller.paperWidth - marginRightForDinA4 - point.xLeft
    const lines: string[] = controller.doc.splitTextToSize(text, size)

    const currPoint: IPoint = SurveyHelper.clone(point)
    const composite: CompositeBrick = new CompositeBrick()
    lines.forEach((line: string) => {
      const size: ISize = controller.measureText(line)
      composite.addBrick(
        new fabric(
          question,
          controller,
          SurveyHelper.createRect(currPoint, size.width, size.height),
          line,
        ),
      )
      currPoint.yTop += size.height
    })
    return composite
  }
}
