import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs'

import { getDisplayableErrorRoute } from '../../app/user-message/displayable-errors'
import { isAdisWfaEnv, WfaEnv } from '../../environments/wfa-env'
import { FormResponseData } from '../../schemas/interfaces/form-response-data'
import { WfaForm } from '../../schemas/interfaces/wfa-form'
import { Maybe } from '../../types/maybe'
import { newWfaMessage } from '../../types/wfa-message'
import { encryptFormResponseData } from '../test/test-viewer/encrypt-form-response-data'
import { httpOptions } from './http-params'
import { replaceUmlauts } from './replace-umlauts'

export function submitFormResponseDataFromPermalink(
  form: Maybe<WfaForm>,
  formResponseData: FormResponseData,
  httpClient: HttpClient,
  wfaEnv: WfaEnv,
  router: Router,
  certBase64: Maybe<string> = undefined,
): Observable<unknown> {
  const url = `${wfaEnv.serverUrl}${wfaEnv.serverUrlSubdirectory}/createWfaFormResponseFromPermalink`
  const body$: Observable<object> = isAdisWfaEnv(wfaEnv)
    ? encryptFormResponseData(formResponseData, certBase64, wfaEnv).pipe(
        map((encrypted) => ({ form, ...encrypted })),
      )
    : of({
        form: replaceUmlauts(form),
        formResponse: replaceUmlauts(formResponseData.formResponse),
        formIsValid: formResponseData.formIsValid,
      })

  return body$.pipe(
    switchMap((body) =>
      httpClient.post(url, body, httpOptions).pipe(
        tap((v) => {
          wfaEnv.logger.log(
            `[WFA] submit-form-response-data-from-permalink: calling post with url ${url}, got response ${JSON.stringify(
              v,
            )}`,
          )
        }),
        catchError((error) => {
          wfaEnv.logger.error(
            '[WFA] submit-form-response-data-from-permalink: called post with url ${url}, got error ',
            error,
          )
          router.navigate([
            `${wfaEnv.frontendUrlSubdirectory}/user-message/`,
            getDisplayableErrorRoute('q5709', error?.message),
          ])
          return of(
            newWfaMessage(
              `[WFA] submitFormResponseDataFromPermalink: ${error?.message}`,
            ),
          )
        }),
      ),
    ),
  )
}
