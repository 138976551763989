import { SurveyjsPropertyInfo } from '../../../../../schemas/interfaces'

export const penColor: SurveyjsPropertyInfo = {
  name: 'penColor',
  surveyElements: ['signaturepad'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: '#2B2C7C',
    },
  ],
}
