import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { staticIdDict } from '../../wfb/populate-test-db/praxis-id.dict.test-db'
import { ZsSupportComponent } from '../adis/zs-support/zs-support.component'

@Component({
  standalone: true,
  selector: 'app-test-zs-support',
  template: `<wfa-zs-support [praxisId]="praxisId"></wfa-zs-support>`,
  imports: [ZsSupportComponent],
})
export class TestZsSupportComponent {
  praxisId = staticIdDict.zollsoft

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation()
    if (navigation?.extras.state) {
      this.praxisId = navigation.extras.state['navData']['praxisId']
    }
  }
}
