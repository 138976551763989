import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatMenuModule } from '@angular/material/menu'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faFilePdf, faGlobe, faInfo } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'

import { CoreLocale } from '../../../schemas/interfaces/core-locale'
import { coreLocales } from '../../../schemas/joi/core-locale.joi'
import { wfaVersion } from '../../../wfa-version'
import { ViewerStore } from '../viewer.store'
import { VersionComponent } from './../../utility-components/version.component'

@Component({
  standalone: true,
  selector: 'wfa-viewer-header',
  templateUrl: './viewer-header.component.html',
  styleUrls: ['./viewer-header.component.scss', '../../../scss/mat-card.scss'],
  providers: [],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule,
    RxLet,
    TranslateModule,
    VersionComponent,
  ],
})
export class ViewerHeaderComponent {
  readonly faGlobe = faGlobe
  readonly faFilePdf = faFilePdf
  readonly faInfo = faInfo
  readonly locales = coreLocales
  readonly wfaVersion = wfaVersion
  localeControl = new FormControl<CoreLocale>('de')

  constructor(
    private readonly translateService: TranslateService,
    public readonly viewerStore: ViewerStore,
  ) {}

  changeLanguage = (lang: string) => {
    this.translateService.use(lang)
    this.viewerStore.setLocale(lang)
  }
}
