import { WfaFormResponse } from '../interfaces/wfa-form-response'

export const dummyPersonalDataWfaFormResponse: WfaFormResponse = {
  responseId: '6517143d15cf05768196c2f6',
  wfaFormId: 'personal-data',
  wfaFormVersionId: '6516cfa361b7627268a81a0c',
  praxisId: '651161879124139e2489b1e2',
  surveyjsResponse: {},
  isDebugEntry: true,
}
