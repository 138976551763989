export function convertToCodename(input: string): string {
  const umlautsMap: Record<string, string> = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    Ä: 'Ae',
    Ö: 'Oe',
    Ü: 'Ue',
    ß: 'ss',
  }

  // Step 1: Replace umlauts, make everything lowercase
  let result = input.replace(/[äöüÄÖÜß]/g, (match) => umlautsMap[match])

  result = result.toLowerCase()

  // Step 2: Replace non-alphabetic and non-special characters with '-'
  result = result.replace(/[^a-zA-Z0-9.-]/g, '-')

  // Step 3: Remove duplicate '-' characters
  result = result.replace(/-+/g, '-')
  result = result.replace(/\.+/g, '.')

  // Step 4: Trim '-' characters from the beginning and end
  result = result.replace(/^-|-$/g, '')
  result = result.replace(/^\.|\.$/g, '')

  return result
}
