import { SurveyjsPropertyInfo } from '../../../../../schemas/interfaces'

export const waitForUpload: SurveyjsPropertyInfo = {
  name: 'waitForUpload',
  surveyElements: ['file'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: true,
    },
  ],
}
