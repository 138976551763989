import { AddressFormGroupTemplate } from "@a-d/forms/fields/create-address-form-group";
import { ADValidators } from "@a-d/forms/validators/a-d-validators";
import { dynamicallyRequired } from "@a-d/wfr/wfa/forms/form-validators";
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators
} from "@angular/forms";
import vld from "./validators";

export const createDefaultEmailControl = () => new FormControl<string>("", [
  dynamicallyRequired,
  Validators.email,
  Validators.pattern(/.+@.+\..+/), //invalidate dotless addresses ADI-1729
]);

interface PersonalFormGroupTemplate extends Partial<AddressFormGroupTemplate> {
  fname: FormControl<string | null>,
  lname: FormControl<string | null>,
  nameTitle: FormControl<string | null>,
  nameAffix: FormControl<string | null>,
  email: FormControl<string | null>,
  emailConfirm: FormControl<string | null>,
  privacyPolicy: FormControl<boolean | null>,
  birthDate?: UntypedFormControl
}
export type PersonalFormGroup = FormGroup<PersonalFormGroupTemplate>

export const createDefaultPersonalFormGroup = () => {
  const emailControl = createDefaultEmailControl()
  return new FormGroup<PersonalFormGroupTemplate>({
  fname: new FormControl<string | null>("", vld.nameValidators),
  lname: new FormControl<string | null>("", vld.nameValidators),
  nameTitle: new FormControl<string | null>(""),
  nameAffix: new FormControl<string | null>(""),
  email: emailControl,
  emailConfirm: new FormControl<string>("", [
    dynamicallyRequired,
    ADValidators.matchStringOfControl(emailControl),
  ]),
  privacyPolicy: new FormControl<boolean | null>(null, [dynamicallyRequired]),
})
}
