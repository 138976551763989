import { nullish } from '../../utility/nullish'
import { encodeRouteParam } from './encode-route-param'

interface DisplayableErrorCore {
  title: string
  body?: string
}

export type DisplayableError = DisplayableErrorCore & {
  code: string
}

export const displayableErrorCores: Record<string, DisplayableErrorCore> = {
  q5702: {
    title:
      'Bei der Weiterleitung zum Editor ist ein Routing-Fehler aufgetreten.',
  },
  q5703: { title: 'joi validation error' },
  q5704: { title: 'selectFormByMessageType: empty argument' },
  q5705: { title: 'Could not parse dynamic error route parameters' },
  q5706: { title: 'Received error while loading the form from server' },
  q5707: { title: 'Could not generate dynamic error route' },
  q5708: { title: 'Route not found' },
  q5709: { title: 'Could not submit form' },
  q5710: { title: 'invalid wfaFormResponse in webview-viewer' },
  tmp4302: {
    title:
      'Your data is being encrypted and transmitted. Please do not close this page...',
  },
  tmp4303: {
    title: 'Your data was submitted successfully, you may now close this page.',
  },
}

export function parseErrorBody(s: string): string {
  return s.replace(/~/g, '<tilde>')
}

export function getDisplayableError(
  errorKey: string,
  body?: unknown,
): DisplayableError {
  const code = Object.keys(displayableErrorCores).includes(errorKey)
    ? errorKey
    : 'q5707'
  const core = displayableErrorCores[code]
  if (nullish(body)) return { code, ...core }

  const strBody = JSON.stringify(body)
  return { ...core, code, body: strBody }
}

export function getDisplayableErrorRoute(
  errorKey: string,
  body?: unknown,
): string {
  const error = getDisplayableError(errorKey, body)
  if (nullish(error.body)) return `dynamic~${error.code}`
  return `dynamic~${error.code}~${encodeRouteParam(error.body)}`
}

export function getDisplayableErrorAsSurveyjs(
  errorKey: string,
  eBody?: unknown,
): object {
  const error = getDisplayableError(errorKey, eBody)
  const title = `[WFA Message ${error.code}]: ${error.title}`
  const rbody = error.body ?? error.title
  const body = `<code>${rbody}</code>`
  return {
    title: {
      default: title,
      de: title,
      en: title,
    },
    pages: [
      {
        name: 'page1',
        elements: [
          {
            type: 'html',
            name: 'question1',
            html: {
              default: body,
              de: body,
              en: body,
            },
          },
        ],
      },
    ],
  }
}
