import { CustomPropertyInfo } from '../../../../schemas/interfaces/custom-property-info'
import { Dictionary } from '../../../types/dictionary'
import { mergeObjects } from '../../../utility/merge-objects'
import { editorOnlyProperties } from './editor-only/_editor-only.barrel'
import { sharedProperties } from './shared/_shared.barrel'

export const customEditorProperties = mergeObjects(
  ...sharedProperties,
  ...editorOnlyProperties,
) as Dictionary<CustomPropertyInfo[]>

export const customViewerProperties = mergeObjects(
  ...sharedProperties,
) as Dictionary<CustomPropertyInfo[]>
