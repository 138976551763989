import Joi from 'joi'

import { zollsoftForms } from '../../assets/json/zollsoft/_zollsoft-forms.barrel'
import { LocStringJoi } from './loc-string.joi'
import { WfaPanelJoi } from './wfa-panel.joi'
import { WfaQuestionJoi } from './wfa-question/wfa-question.joi'

export const WfaPageJoi = Joi.object()
  .keys({
    name: Joi.string().optional(),
    description: LocStringJoi.optional(),
    title: LocStringJoi.optional(),
    visibleIf: Joi.string().optional(),
    elements: Joi.array().items(Joi.alternatives(WfaPanelJoi, WfaQuestionJoi)),
  })
  .meta({
    className: 'WfaPage',
  })
  .example(zollsoftForms.personalData.pages?.[0])
