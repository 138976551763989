import { WfaQuestionType } from '../../../../../schemas/interfaces/wfa-question-type'

interface Category {
  category: string
  items: WfaQuestionType[]
}

export const customCategories: Category[] = [
  {
    category: 'Basic',
    items: [
      'text',
      'comment',
      'radiogroup',
      'checkbox',
      'dropdown',
      'tagbox',
      'html',
      'signaturepad',
    ],
  },
  {
    category: 'Erweitert',
    items: [
      'boolean',
      'buttongroup',
      'empty',
      'expression',
      'file',
      'image',
      'imagepicker',
      'matrix',
      'matrixdropdown',
      'matrixdropdownbase',
      'matrixdynamic',
      'multipletext',
      'nonvalue',
      'panel',
      'paneldynamic',
      'ranking',
      'rating',
      'textbase',
    ],
  },
  {
    category: 'Vordefiniert',
    items: [
      'birthdate',
      'gender',
      'academic-title',
      'country',
      'contact',
      'sending-information',
    ],
  },
]
