import { Question } from 'survey-core'
import { SurveyCreatorModel } from 'survey-creator-core'

import { CoreLocale, WfaQuestionType } from '../../../../schemas/interfaces'
import { nullish } from '../../../../utility/nullish'
import {
  lookUpLocalizedShortTitleFromWfaKey,
  questionType2WfaKey,
} from './wfa-key/wfa-key-utils'

/**
 * "question change" implies the change of a question's type or addition of a new question to the survey
 */
export function updatePropertiesOnQuestionChange(
  creator: SurveyCreatorModel,
): void {
  creator.onQuestionAdded.add((creator, options) => {
    const question = options.question

    resetValidators(question)
    updatePropertiesWithWfaKey(creator, question)
  })
}

function resetValidators(question: Question) {
  question.validators = []
}

function updatePropertiesWithWfaKey(
  creator: SurveyCreatorModel,
  question: Question,
): void {
  const questionType = question.getType() as WfaQuestionType
  const wfaKey = questionType2WfaKey(questionType)
  if (nullish(wfaKey)) return

  question['wfaKey'] = wfaKey
  question.name = wfaKey

  const locale = creator.locale as CoreLocale
  const shortTitle = lookUpLocalizedShortTitleFromWfaKey(wfaKey, locale)
  if (nullish(shortTitle)) return

  if (nullish(question['shortTitle'])) question['shortTitle'] = shortTitle
  if (question['title'] === wfaKey) question['title'] = shortTitle
}
