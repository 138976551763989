import { Maybe } from '../../../../../types/maybe'
import { nullish } from '../../../../../utility/nullish'

/**
 * Based on the validation in arzt-direkt (`vnrValidator`)
 * @see `arzt-direkt/projects/arzt-direkt/src/app/forms/form-validators.service.ts`
 */
export function isValidInsurantNumber([input]: (string | number)[]): boolean {
  if (nullish(input)) return true
  if (typeof input === 'number') input = String(input)
  if (input.length !== 10) return false

  const firstCharPosition = calculateFirstCharPositionInAlphabet(input)
  if (nullish(firstCharPosition)) return false

  const checksum = calculateChecksum(input, firstCharPosition)
  return validateWithCheckDigit(input, checksum)
}

function calculateFirstCharPositionInAlphabet(input: string): Maybe<string> {
  const firstCharAsUnicode = input.toLowerCase().charCodeAt(0)
  // a is the first letter of the alphabet, its ASCII code is 97
  const aLowercaseAsUnicode = 97
  const positionInAlphabet = firstCharAsUnicode - aLowercaseAsUnicode + 1
  // return undefined if first character is not in the alphabet
  if (
    isNaN(positionInAlphabet) ||
    positionInAlphabet > 26 ||
    positionInAlphabet <= 0
  )
    return undefined
  // create two digit string if position in alphabet is < 10
  return positionInAlphabet < 10
    ? `0${positionInAlphabet}`
    : String(positionInAlphabet)
}

function calculateChecksum(input: string, firstCharPosition: string): number {
  const newString = firstCharPosition + input.substring(1, 9)
  const chars = newString.split('')

  let sum = 0
  chars.forEach((char, index) => {
    const factor = index % 2 === 0 ? 1 : 2
    const multiplication = parseInt(char) * factor
    const digitSum = calculateDigitSum(multiplication)
    sum += digitSum
  })
  return sum
}

function calculateDigitSum(multiplication: number): number {
  const absoluteValue = Math.abs(multiplication)
  const digits = absoluteValue.toString().split('')
  return digits.reduce(function (a, b) {
    return +a + +b
  }, 0)
}

function validateWithCheckDigit(input: string, checksum: number) {
  const checkDigit = checksum % 10
  const lastDigit = input.split('').pop()
  if (nullish(lastDigit)) return false
  const givenCheckDigit = parseInt(lastDigit)
  if (givenCheckDigit !== checkDigit) return false
  return true
}
