import { WfaForm } from '../../../schemas/interfaces'
import { CoreLocale } from '../../../schemas/interfaces'
import { Maybe } from '../../../types/maybe'
import { notNullish } from '../../../utility/nullish'
import { replaceUmlautsInString } from '../../utility/replace-umlauts'
import { initialViewerState } from '../viewer.state'
import { formatDate } from './format-date'

const defaultLocale = initialViewerState.env.locale

export function formatTitleToFileName(
  locale: CoreLocale,
  wfaForm: WfaForm,
  date: Date,
): string {
  const title = getTitle(locale, wfaForm)
  const formattedTitle = notNullish(title)
    ? formatTitle(title)
    : 'unbekannter-titel'
  const formattedDate = formatDate(date, '-')
  return `${formattedTitle}_${formattedDate}`
}

function getTitle(locale: CoreLocale, wfaForm: WfaForm): Maybe<string> {
  const title = wfaForm.title
  if (typeof title === 'string') return title
  return title[locale] ?? title[defaultLocale]
}

function formatTitle(title: string): string {
  let formatted = title.toLowerCase()
  formatted = replaceUmlautsInString(formatted)
  formatted = formatted.replace(/[^a-z\s-]/g, '')
  return formatted.replace(/\s/g, '-')
}
