import { CustomPropertyInfo } from '../../../../../schemas/interfaces/custom-property-info'

const wfaVersion: CustomPropertyInfo = {
  name: 'wfaVersion',
  type: 'string',
  visible: false,
}

const surveyElements = ['survey']

export const surveyElementsWithWfaVersion = surveyElements.reduce(
  (acc, item) => {
    acc[item] = wfaVersion
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
