import Joi from 'joi'

import { WfaFormDraftUpdateQueryResult } from './../../../../interfaces/wfb/routes/wfa-form-draft/wfa-form-draft-update.query-result'

export const WfaFormDraftUpdateQueryResultJoi: Joi.ObjectSchema<WfaFormDraftUpdateQueryResult> =
  Joi.object()
    .keys({
      updatedAt: Joi.number().required().description('Timestamp of the update'),
    })
    .meta({
      className: 'WfaFormDraftUpdateQueryResult',
    })
    .example({
      updatedAt: '1717407416500',
    })
