import { map, Observable, of } from 'rxjs'

import { AdisWfaEnv } from '../../../environments/wfa-env'
import { PraxisId, SurveyjsResponse } from '../../../schemas/interfaces'
import { Maybe } from '../../../types/maybe'
import { nullish } from '../../../utility/nullish'
import { safeStructuredClone } from '../../utility/lodash-alternatives/safe-structured-clone'
import { set } from '../../utility/lodash-alternatives/set'

interface MinFormResponseData {
  formResponse: {
    surveyjsResponse?: Maybe<SurveyjsResponse>
    praxisId: PraxisId
  }
}

export function encryptFormResponseData<T extends MinFormResponseData>(
  formResponseData: T,
  certBase64: Maybe<string>,
  wfaEnv: AdisWfaEnv,
): Observable<T> {
  const unencrypted = formResponseData.formResponse.surveyjsResponse ?? {}
  if (Object.keys(unencrypted).length === 0) return of(formResponseData)

  if (nullish(certBase64)) {
    const errorMsg =
      '[WFA] encryptFormResponseData: could not find certificate to encrypt patient data'
    console.error(errorMsg)
    return of(
      set<T>(
        safeStructuredClone(formResponseData),
        'formResponse.surveyjsResponse',
        {
          __wfaErrorMsg__: errorMsg,
        },
      ),
    )
  }

  return wfaEnv
    .encryptString(certBase64, JSON.stringify(unencrypted))
    .pipe(
      map((encryptedSurveyjsResponse) =>
        set<T>(
          safeStructuredClone(formResponseData),
          'formResponse.surveyjsResponse',
          { encryptedSurveyjsResponse },
        ),
      ),
    )
}
