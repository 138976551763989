import { CustomPropertyInfo } from '../../../../../schemas/interfaces/custom-property-info'

const wfaFormId: CustomPropertyInfo = {
  name: 'wfaFormId',
  type: 'string',
  visible: false,
}

const surveyElements = ['survey']

export const surveyElementsWithWfaFormId = surveyElements.reduce(
  (acc, item) => {
    acc[item] = wfaFormId
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
