import { Action, ComputedUpdater } from 'survey-core'
import { SurveyCreatorModel } from 'survey-creator-core'

import { Maybe } from './../../../../../types/maybe'

interface ToolboxActionDetails {
  action: () => void
  enabled?: Maybe<boolean>
  iconName?: string
  id: string
  index: number
  showTitle?: Maybe<boolean>
  title: string
  tooltip?: Maybe<string>
}

export function addToolboxAction(
  creator: SurveyCreatorModel,
  {
    action,
    enabled,
    iconName,
    id,
    index,
    showTitle,
    title,
    tooltip,
  }: ToolboxActionDetails,
) {
  const buttonAction = new Action({
    action,
    enabled: enabled ?? true,
    iconName,
    id,
    showTitle: showTitle ?? false,
    title,
    tooltip: tooltip ?? title,
    visible: new ComputedUpdater(() => {
      return creator.activeTab === 'designer'
    }),
  })

  // push as the item in index's place
  creator.toolbarItems.splice(index, 0, buttonAction)
  // Add the custom button to the bottom toolbar (visible only on mobile devices)
  creator.footerToolbar.actions.splice(index, 0, buttonAction)
}
