import 'survey-core/survey.i18n'

import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import {
  defaultAdColors,
  defaultTomedoColors,
  setDocumentStyles,
} from '@arzt-direkt/colors'
import {
  FormResponseData,
  WfaFormWithResponse,
  WfaFormResponse,
  WfaForm,
  ViewerEnvState,
  IsEmbeddedIn,
  FormResponseOrigin,
  DisplayOption,
} from '@arzt-direkt/wfa-definitions'
import { notNullish, Maybe, set } from '@arzt-direkt/wfa-generic-utils'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faFilePdf, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import {
  filter,
  map,
  Observable,
  Observer,
  of,
  take,
  withLatestFrom,
} from 'rxjs'
import { SurveyModule } from 'survey-angular-ui'
import { Model } from 'survey-core'

import { setSurveyConfiguration } from './setup/set-survey-configuration'
import { updateSurveyModelWithEnvState } from './setup/update-survey-model-with-env-state'
import { exportSurveyToPdf } from './surveyjs-pdf-export/export-survey-to-pdf'
import { ViewerStore } from './viewer.store'
import { ViewerHeaderComponent } from './viewer-header/viewer-header.component'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: [
    './viewer.component.scss',
    '../../scss/mat-card.scss',
    '../../scss/button.scss',
  ],
  providers: [ViewerStore],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    ReactiveFormsModule,
    SurveyModule,
    TranslateModule,
    ViewerHeaderComponent,
  ],
})
export class ViewerComponent implements OnInit {
  readonly faGlobe = faGlobe
  readonly faFilePdf = faFilePdf

  /**
   * Use `form$`, `formResponse$`, `envState$` to
   * communicate `ViewerComponent` consumer's state to `ViewerComponent`
   * (Preload answers, change environment or form, etc).
   *
   * Use `viewerStore.form$`, `viewerStore.formResponse$` and `viewerStore.envState$`
   * to store communicate `ViewerComponent`'s state to its consumer.
   * (Submit answers etc).
   */
  @Input() form$ = new Observable<Maybe<WfaForm>>()
  @Input() formResponse$ = new Observable<Maybe<WfaFormResponse>>()
  @Input() envState$ = new Observable<ViewerEnvState>()
  @Input() readOnly = false
  @Output() formResponseDataEmitter = new EventEmitter<FormResponseData>()

  surveyModel: Model = new Model()
  isEmbeddedIn: IsEmbeddedIn = 'arztDirekt'
  displayOption: DisplayOption = 'editable'
  submittedAt: Maybe<number>
  completed = false

  constructor(
    private readonly translateService: TranslateService,
    private readonly viewerStore: ViewerStore,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    setSurveyConfiguration(this.surveyModel, this.dialog)

    this.surveyModel.onValueChanged.add(() => this.emitFormResponseData('blur'))
    this.surveyModel.onComplete.add(() => {
      this.emitFormResponseData('completeButton')
      this.completed = true
    })

    this.form$.subscribe(this.viewerStore.setForm)
    this.formResponse$.subscribe((formResponse: Maybe<WfaFormResponse>) => {
      this.submittedAt = formResponse?.submittedAt
      this.viewerStore.setFormResponse(formResponse)
    })

    this.envState$.subscribe((env: ViewerEnvState) => {
      this.viewerStore.setEnv(env)
      this.isEmbeddedIn = env.isEmbeddedIn
      this.displayOption = env.displayOption
      updateSurveyModelWithEnvState(this.surveyModel, env, this.dialog)

      updateDocumentStyles(this.isEmbeddedIn)
    })

    this.viewerStore.locale$.subscribe((locale) => {
      this.surveyModel.locale = locale
    })

    // determine if the form can be skipped altogether without filling out
    this.viewerStore.form$.subscribe((form) => {
      this.surveyModel.fromJSON(form)
      this.emitFormResponseData('validateOnRender')
    })

    // determine if the form can be skipped altogether without filling out
    this.viewerStore.formResponse$.subscribe((formResponse) => {
      this.surveyModel.data = formResponse?.surveyjsResponse
      this.emitFormResponseData('validateOnRender')
    })
  }

  count = 0

  emitFormResponseData(origin: FormResponseOrigin): void {
    of(null)
      .pipe(
        withLatestFrom(this.form$, this.formResponse$),
        map(([_, form, formResponse]) => ({
          form,
          formResponse,
        })),
        filter(({ form }) => notNullish(form)),
        filter(({ formResponse }) => notNullish(formResponse)),
        map((data) => data as { form: WfaForm; formResponse: WfaFormResponse }),
        take(1),
      )
      .subscribe(({ form, formResponse }) => {
        const submittedAt = origin === 'completeButton' ? Date.now() : undefined
        if (notNullish(submittedAt)) this.submittedAt = submittedAt

        const formResponseData: WfaFormWithResponse & FormResponseData = {
          formIsValid: checkFormValidity(this.surveyModel),
          form,
          formResponse: {
            responseId: formResponse?.responseId,
            wfaFormId: form.wfaFormId,
            wfaFormVersionId: form.wfaFormVersionId,
            patientId: formResponse.patientId,
            praxisId: form.praxisId ?? formResponse.praxisId,
            surveyjsResponse: replaceSpecialCharacters(
              this.surveyModel.data,
              this.isEmbeddedIn,
            ),
            origin,
            submittedAt,
          },
        }

        this.formResponseDataEmitter.emit(formResponseData)
      })
  }

  exportPdf = () => exportSurveyToPdf(this.surveyModel, this.submittedAt)

  completeForm = () => this.surveyModel.completeLastPage()

  subscribe = (obs: Observable<unknown>, func: Partial<Observer<unknown>>) => {
    return obs.pipe(untilDestroyed(this)).subscribe(func)
  }
}

function checkFormValidity(surveyModel: Model): boolean {
  return surveyModel.validate(false)
}

function updateDocumentStyles(isEmbeddedIn: IsEmbeddedIn) {
  if (isEmbeddedIn === 'iOsTomedo')
    setDocumentStyles(document, defaultTomedoColors)
  if (isEmbeddedIn === 'arztDirekt')
    setDocumentStyles(document, defaultAdColors())
}

function replaceSpecialCharacters(
  response: Record<string, string>,
  isEmbeddedIn: IsEmbeddedIn,
): Record<string, string> {
  if (isEmbeddedIn !== 'arztDirekt') return response

  let _response = structuredClone(response)
  Object.entries(_response)
    .filter(([_, value]) => typeof value === 'string')
    .forEach(([key, value]) => {
      set(_response, key, value.replace(/"/g, "'"))
    })
  return _response
}
