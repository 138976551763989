import {
  EmailValidator,
  ExpressionValidator,
  QuestionTextModel,
  SurveyValidator,
} from 'survey-core'

import { InputType } from '../../../../../schemas/interfaces'
import { Maybe } from '../../../../../types/maybe'
import { nullish } from '../../../../../utility/nullish'

interface ValidatorInfo {
  validator: SurveyValidator
  text: string
}

interface RemovalInfo {
  validatorType: string
  searchString: string
}

export function addValidator(
  inputType: InputType,
  question: QuestionTextModel,
): void {
  const validatorInfo = lookupValidatorInfo(inputType, question)
  if (nullish(validatorInfo)) return

  const { validator, text } = validatorInfo
  validator.text = text
  question.validators.push(validator)
}

export function removeValidator(
  inputType: InputType,
  question: QuestionTextModel,
): void {
  const removalInfo = lookupRemovalInfo(inputType)
  if (nullish(removalInfo)) return
  question.validators = filterValidators(removalInfo, question)
}

function lookupValidatorInfo(
  inputType: InputType,
  question: QuestionTextModel,
): Maybe<ValidatorInfo> {
  const validatorInfoDict: Record<string, ValidatorInfo> = {
    'custom-email': {
      validator: new EmailValidator(),
      text: 'Ungültige E-Mail Adresse.',
    },
    'custom-insurant-number': {
      validator: new ExpressionValidator(
        `isValidInsurantNumber({${question.name}})`,
      ),
      text: 'Ungültige Versichertennummer.',
    },
    'custom-insurance-number': {
      validator: new ExpressionValidator(
        `isValidInsuranceNumber({${question.name}})`,
      ),
      text: 'Ungültige Kassennummer.',
    },
    'custom-number': {
      validator: new ExpressionValidator(`isValidNumber({${question.name}})`),
      text: 'Die Eingabe muss eine Zahl sein.',
    },
    'custom-phone-number': {
      validator: new ExpressionValidator(
        `isValidPhoneNumber({${question.name}})`,
      ),
      text: 'Ungültige Telefonnummer.',
    },
  }
  return validatorInfoDict[inputType]
}

function lookupRemovalInfo(inputType: InputType): Maybe<RemovalInfo> {
  const searchStringDict: Record<string, RemovalInfo> = {
    'custom-email': {
      validatorType: 'email',
      searchString: '',
    },
    'custom-insurant-number': {
      validatorType: 'expression',
      searchString: 'isValidInsurantNumber',
    },
    'custom-insurance-number': {
      validatorType: 'expression',
      searchString: 'isValidInsuranceNumber',
    },
    'custom-number': {
      validatorType: 'expression',
      searchString: 'isValidNumber',
    },
    'custom-phone-number': {
      validatorType: 'expression',
      searchString: 'isValidPhoneNumber',
    },
  }
  return searchStringDict[inputType]
}

function filterValidators(
  removalInfo: RemovalInfo,
  question: QuestionTextModel,
): SurveyValidator[] {
  const { validatorType, searchString } = removalInfo
  return question.validators.filter((validator) => {
    if (validatorType === 'email')
      return validator.getType() !== 'emailvalidator'
    if (
      validatorType === 'expression' &&
      validator instanceof ExpressionValidator
    )
      return !validator.expression.includes(searchString)
    return true
  })
}
