import { Id } from '../interfaces/id'
import { Maybe } from './../../types/maybe'
import { notNullish, nullish } from './../../utility/nullish'

export function id2String(id: Maybe<Id>): string {
  if (nullish(id)) {
    console.info(`[id2String]: nullish input.`)
    return ''
  }

  const { $oid } = id as { $oid: string }
  if (notNullish($oid) && typeof $oid === 'string') return $oid

  if (notNullish(id.toString)) {
    return id.toString()
  }

  if (typeof id === 'string') return id
  console.error(`[id2String]: could not convert ${id} to string.`)
  return ''
}
