import dayjs, { Dayjs } from 'dayjs'

import {
  AdFileFormat,
  SurveyjsFileResponseFormat,
} from '../../../../schemas/interfaces/file-upload'
import { notNullish } from '../../../../utility/nullish'

export function wrapToAdFile(
  surveyjsFile: SurveyjsFileResponseFormat,
  index: number,
): AdFileFormat {
  const AdFileHeaderBase = {
    capturedRole: 'patient',
    description: 'Patient-Upload',
    origin: 'upload',
    senderRole: 'patient',
  }
  const { content, name, type } = surveyjsFile
  const base64str = content.substring(content.indexOf(',') + 1)
  const size = atob(base64str).length
  const now = dayjs()
  const createdAt = now.toISOString()
  return {
    dataUri: content,
    meta: {
      ...AdFileHeaderBase,
      name: formatFileName(now, name, index),
      createdAt,
      size,
      type,
    },
  }
}

function formatFileName(date: Dayjs, name: string, index: number): string {
  const baseName = `${dayjs(date).format(
    'DD-MM-YY_HH-mm-ss-SSS',
  )}_Upload_Patient`
  const extension = /(?:.([^.]+))?$/.exec(name)
  if (notNullish(extension) && notNullish(extension[1]))
    return `${baseName}_${index}.${extension[1]}`
  return `${baseName}_${index}`
}
