import Joi from 'joi'

import * as v1_5_0 from './wfa-form-versions/wfa-form-v1-5-0.joi'
import * as v2_7_6 from './wfa-form-versions/wfa-form-v2-7-6.joi'
import * as v2_9_0 from './wfa-form-versions/wfa-form-v2-9-0.joi'

export const MostRelaxedWfaFormJoi = v1_5_0.WfaFormJoi.meta({
  className: 'MostRelaxedWfaForm',
})

export const wfaFormDefault = v2_9_0.wfaFormDefault
export const WfaFormDraftJoi = v2_9_0.WfaFormJoi.meta({
  className: 'WfaFormDraft',
})

export const WfaFormJoi = v2_9_0.WfaFormJoi.meta({
  className: 'WfaForm',
})

export const AnyWfaFormJoi = Joi.alternatives()
  .try(v1_5_0.WfaFormJoi, v2_7_6.WfaFormJoi, v2_9_0.WfaFormJoi)
  .meta({
    className: 'AnyWfaForm',
  })

export const wfaFormJoiDict = {
  'v1.5.0': v1_5_0.WfaFormJoi,
  'v2.7.6': v2_7_6.WfaFormJoi,
  'v2.9.0': v2_9_0.WfaFormJoi,
}
