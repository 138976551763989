import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { MatTableModule } from '@angular/material/table'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'
import { of, take } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'

import { WfaEnvService } from '../../../../environments/wfa-env.service'
import {
  PraxisId,
  WfaFormId,
  WfaFormStatus,
} from '../../../../schemas/interfaces'
import { Id } from '../../../../schemas/interfaces/id'
import { isWfaMessage } from '../../../../types/wfa-message'
import { validateWfaForm } from '../../../../utility/validate-wfa-form'
import { getLastElement } from '../../../utility/array/get-last-element'
import { I18nPipe } from '../../../utility/i18n.pipe'
import { mongoIdToLocaleString } from '../../../utility/mongo-id-to-locale-string'
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component'
import { navigateToAdisEditor } from '../navigate-to-adis-editor'
import { updateInfos, ZsSupportService } from '../zs-support.service'
import { ZsSupportCommsService } from '../zs-support-comms.service'
import { WfaForm } from './../../../../schemas/interfaces/wfa-form'
import { notNullish } from './../../../../utility/nullish'
import { copyFormOrDraft } from './../copy-form-or-draft'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-form-table',
  styleUrls: [
    './form-table.component.scss',
    '../support-text-button.scss',
    '../mat-card.scss',
  ],
  templateUrl: './form-table.component.html',
  imports: [
    CommonModule,
    I18nPipe,
    MatCardModule,
    MatTableModule,
    RxLet,
    TranslateModule,
    ActionButtonsComponent,
  ],
})
export class FormTableComponent {
  readonly getLastElement = getLastElement
  readonly mongoIdToLocaleString = mongoIdToLocaleString

  displayedColumns: string[] = ['identifier', 'version', 'actions']

  constructor(
    private comms: ZsSupportCommsService,
    private router: Router,
    private translate: TranslateService,
    public wfaEnvService: WfaEnvService,
    public zsSupportService: ZsSupportService,
  ) {}

  copyForm(wfaFormVersionId: Id, wfaFormId: WfaFormId, praxisId: PraxisId) {
    copyFormOrDraft(
      wfaFormVersionId,
      wfaFormId,
      praxisId,
      this.zsSupportService,
      this.comms,
      this.comms.getForm(praxisId.toString(), wfaFormId).pipe(
        filter(notNullish),
        map((r) => r.queryResult as WfaForm),
      ),
      (f: WfaForm) => this.comms.publishForm(f),
    )
      .pipe(untilDestroyed(this))
      .subscribe()
  }

  openDraftEditor(wfaFormVersionId: Id) {
    this.zsSupportService.showSpinner$.next(true)
    return this.comms
      .createFormDraft(wfaFormVersionId)
      .pipe(
        catchError((error) => {
          console.error(`[openDraftEditor]: ${error.message}`)
          this.zsSupportService.showSpinner$.next(false)
          return of(null)
        }),
        untilDestroyed(this),
      )
      .subscribe((response: any) => {
        const wfaForm = validateWfaForm(response.queryResult)
        if (isWfaMessage(wfaForm)) {
          console.error(
            '[openDraftEditor]: expected valid formDraft, got',
            wfaForm.message,
            'for form',
            response.queryResult,
          )
        } else {
          this.zsSupportService.showSpinner$.next(false)
          navigateToAdisEditor(wfaForm, this.router, this.wfaEnvService)
        }
      })
  }

  changeStatus(
    praxisId: PraxisId,
    status: WfaFormStatus,
    wfaFormId: WfaFormId,
  ) {
    this.zsSupportService.showSpinner$.next(true)
    return this.comms
      .updateFormStatus(praxisId, status, wfaFormId)
      .pipe(
        take(1),
        switchMap(() => this.comms.getFormAndDraftInfos(praxisId)),
        tap(() => this.zsSupportService.showSpinner$.next(false)),
        untilDestroyed(this),
      )
      .subscribe(updateInfos(this.zsSupportService))
  }

  changePermalinkState(praxisId: PraxisId, wfaFormId: WfaFormId) {
    this.zsSupportService.showSpinner$.next(true)
    return this.comms
      .updateFormPermalink(praxisId, wfaFormId)
      .pipe(
        take(1),
        switchMap(() => this.comms.getFormAndDraftInfos(praxisId)),
        tap(() => this.zsSupportService.showSpinner$.next(false)),
        untilDestroyed(this),
      )
      .subscribe(updateInfos(this.zsSupportService))
  }
}
