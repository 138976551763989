import Joi from 'joi'

import { idRsaPub } from '../../wfb/test/fake-ssh-credentials/id-rsa.pub'

export const CertificateJoi = Joi.string()
  .description("Cert64Base string used to encrypt patient's response.")
  .meta({
    className: 'Certificate',
  })
  .example(idRsaPub)
