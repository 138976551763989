import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { ActiveInstance } from "@a-d/entities/ActiveInstance.entity";
import { AppointmentType, BookingStep, I18NString_MISSING_VALUE } from "@a-d/entities/Booking.entity";
import { FieldSettings, OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { BaseLanguage } from "@a-d/entities/I18N.entity";
import { InstanceForm } from "@a-d/entities/InstanceForm.entity";
import { LegalDataFiles } from "@a-d/entities/Legal.entity";
import { FormHelpers } from "@a-d/forms/form-helpers.service";
import FormValidators from "@a-d/forms/form-validators.service";
import { LanguageService } from "@a-d/i18n/language.service";
import { InstanceFormInterface } from "@a-d/instance-form/instance-form-component.interface";
import { InstanceFormService } from "@a-d/instance-form/instance-form.service";
import { InstanceService } from "@a-d/instance/instance.service";
import { LegalService } from "@a-d/legal/legal.service";
import { UsefulComponent } from "@a-d/misc/useful.component";
import WfaModule from "@a-d/wfr/wfa/wfa.module";
import { TextFieldModule } from "@angular/cdk/text-field";
import { CommonModule, DatePipe, NgFor, NgIf } from "@angular/common";
import {
  ChangeDetectorRef,
  Component, ElementRef, OnInit, ViewChild
} from "@angular/core";
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxModule, _MatCheckboxRequiredValidatorModule } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { ActivatedRoute } from '@angular/router';
import { environment } from "@env/environment";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import dayjs from 'dayjs';
import { NGXLogger } from "ngx-logger";
import { Observable, Subscriber, Subscription, of } from "rxjs";
import { debounceTime, delay, filter, mergeMap, tap } from "rxjs/operators";
import { I18NHtmlString, I18NString } from '../../entities/Booking.entity';
import { AddressSearchFieldComponent } from "../../forms/fields/address-search-field.component";
import { CheckboxComponent } from "../../forms/fields/checkbox/checkbox.component";
import { NameTitleFieldComponent } from "../../forms/fields/name-title-field.component";
import { SelectionFieldComponent } from "../../forms/fields/selection-field.component";
import { I18NStringPipe } from "../../i18n/i18n.pipe";
import { SanitizeHtmlPipe } from "../../misc/sanitize.pipe";
import { StorageService } from '../../storage/storage.service';
import { CustomDateAdapter, customDateFormats } from '../booking-date/calendar-custom-date-adapter/calendar-custom-date-adapter.component';
import { BookingFileUploadComponent } from "../booking-file-upload/booking-file-upload.component";
import { BookingService } from "../booking.service";
import { FormAndFields, OtkFieldConfigs } from "./booking-personal.d";
import { constructBasicConfig } from "./construct-configs";
import { createDefaultPersonalFormGroup } from "./default-personal-form-group";
import GetData from './get-data';
import { createPersonalFormGroup, populateContactFormGroup } from "./manipulate-forms";
import { maybeAddBirthDate } from "./maybe-add-control";
import { setMagicFillValues } from "./set-magic-fill-values";
import validators from "./validators";


@UntilDestroy()
@Component({
  selector: "app-booking-personal",
  templateUrl: "./booking-personal.component.html",
  styleUrls: ["./booking-personal.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: customDateFormats },
    DatePipe,
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    NameTitleFieldComponent,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    AddressSearchFieldComponent,
    SelectionFieldComponent,
    TextFieldModule,
    NgFor,
    BookingFileUploadComponent,
    CheckboxComponent,
    MatCheckboxModule,
    _MatCheckboxRequiredValidatorModule,
    MatIconModule,
    TranslateModule,
    I18NStringPipe,
    SanitizeHtmlPipe,
  ],
})
export class BookingPersonalComponent extends UsefulComponent
  implements OnInit, InstanceFormInterface, FormAndFields {
  @ViewChild('bookingPersonal', { static: true }) bookingPersonal: ElementRef
  readonly MIN_AGE_FILTER = FormValidators.getMinimumAgeDateFilter(0);
  readonly MIN_BIRTH_DATE = dayjs().subtract(150, 'years').toDate()
  readonly birthDateStart = validators.birthDateStart;
  readonly stdCharLimit = validators.stdCharLimit;
  isMobile: boolean;
  isPersonalFormValid: boolean = false;
  isContactFormSelected: boolean = false
  isZollsoftMode = false
  useSpecificFieldSettings: boolean = false
  settings: {};
  showNameTitleAndAffix = true;
  pediatricianMode = false;
  info: I18NString
  showInfo: boolean = false
  infoHtml: I18NHtmlString
  appointmentTypeSubscription: Subscription = null;
  appointmentType: AppointmentType
  baseLanguage: BaseLanguage
  baseLanguageDefault: BaseLanguage
  baseLanguageSubscription: Subscription
  I18NString_MISSING_VALUE: string = I18NString_MISSING_VALUE // string stored for instance in I18NString.en in case it was not translated yet
  public addressVisible = true
  public contactFormIssues = []
  public isDemo: boolean = environment.demoMode
  public showFileUpload: boolean = false
  public Legal = LegalDataFiles
  public presetBirthDate = false
  personalDataFromFlutter: any // personal form data received by the app
  public appVersion: string

  // basic fields use custom components, it's more convenient to repack them to an object
  basicFields: OtkFieldConfigs = {};
  customFields: OTKFieldConfig[] = [];
  formGroup: UntypedFormGroup;
  private personalFormCachingSubscription: Subscription

  // because personal form can be switched out on runtime we need to manage subscription to value changes
  private personalFormValueChangesSubscription: Subscription
  private emailValueChangesSubscription: Subscription
  subscriptionIsContactFormSelected: Subscription
  subscriptionIsBookingInitialized: Subscription
  subscriptionMagicFill: Subscription
  subscriptionCustomerId: Subscription

  get activeInstance(): ActiveInstance {
    return this.instanceService.activeInstance;
  }
  get activeInstanceForm(): InstanceForm {
    return this.instanceFormService.activeInstanceForm;
  }

  getData = () => GetData.getData(this, this.translate);
  getPatientData = (baseLanguage: BaseLanguage, baseLanguageDefault: BaseLanguage) => GetData.getPatientData(this, baseLanguage, baseLanguageDefault, this.isZollsoftMode);
  getContactData = (baseLanguage: BaseLanguage, baseLanguageDefault: BaseLanguage) => GetData.getContactData(this, baseLanguage, baseLanguageDefault);

  isFormGroupValid(): boolean {
    return WfaModule.WfaFormGroupService.isDynamicallyValid(this.formGroup);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private adLoggerService: AdLoggerService,
    private bookingService: BookingService,
    private formHelpers: FormHelpers,
    private instanceFormService: InstanceFormService,
    private instanceService: InstanceService,
    public translate: TranslateService,
    public languageService: LanguageService,
    private calendarCustomDateAdapter: DateAdapter<Date>,
    public logger: NGXLogger,
    public storageService: StorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private legalService: LegalService
  ) {
    super();
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(
      this.activeInstanceForm,
      this.formGroup,
      this.unsubscribe$
    );
  }

  ngOnInit(): void {
    this.appointmentTypeSubscribe()
    this.setScrollableNativeElement()
    this.isZollsoftMode = this.bookingService?.otkUser?.zollsoftMode;
    this.presetBirthDate = (this.bookingService.isBirthdateActive && !this.isZollsoftMode) ? true : false;
    this.formGroup = maybeAddBirthDate(createDefaultPersonalFormGroup(), this.isZollsoftMode);
    this.managePersonalFormValueChangesSubscription()
    this.baseLanguageSubscribe()
    this.setLanguage()
    this.isContactFormSelectedSubscribe()
    this.bookingService.personalFormComponent = this
    this.subscribeIsBookingInitialized()
    this.pediatricianMode = this.bookingService.otkUser?.fieldSettings?.pediatricianMode ?? false // In case booking is already initialized

    this.isMobile = this.bookingService.isMobile();
    // load fields that need to be displayed from settings
    this.bookingService.isBookingTypeInitialized$
      .pipe(
        untilDestroyed(this),
        filter(initialized => !!initialized),
        mergeMap(() => this.magicFillSubscription()),
        mergeMap(() => {
          const specificFieldSettings = this.bookingService.appointmentType?.value?.useSpecificFieldSettings && this.bookingService.appointmentType?.value?.specificFieldSettings
          this.basicFields = constructBasicConfig(this.bookingService.otkUser.fieldSettings.basic, specificFieldSettings?.basic)
          this.customFields = specificFieldSettings?.custom ?? this.bookingService.otkUser.fieldSettings.custom ?? [];
          this.formGroup = createPersonalFormGroup(
            this.activeInstance,
            this.basicFields,
            this.customFields,
            { zollsoftMode: this.isZollsoftMode }
          )
          this.hardSetBirthdate()
          this.reinitAddressFields()
          this.managePersonalFormValueChangesSubscription()
          this.subscribeToCustomerId()
          return of({ basicFields: this.basicFields, customFields: this.customFields })
        }),
        tap(() => {
          if (this.personalFormCachingSubscription && !this.personalFormCachingSubscription.closed) this.personalFormCachingSubscription.unsubscribe()
          this.personalFormCachingSubscription = this.formHelpers.syncBookingPersonalWithLocalStorage(this.formGroup, "BOOKING-PERSONAL-FORM", this.unsubscribe$)
        }),
        mergeMap(() => this.appointmentTypeSubscribe()),
        mergeMap(() => this.bookingService.isEmbedded ? this.getPersonalDataFromApp() : of(null)),
        mergeMap(() => this.bookingService.isEmbedded ? this.bookingService.updateAppSubscribe() : of(null)),
      )
      .subscribe()
  }

  setScrollableNativeElement() {
    this.bookingService.scrollableNativeElement[BookingStep.bookingPersonal] = this.bookingPersonal.nativeElement
  }

  isContactFormSelectedSubscribe() {
    if (this.subscriptionIsContactFormSelected)
      this.subscriptionIsContactFormSelected.unsubscribe()
    this.subscriptionIsContactFormSelected = this.bookingService.isContactFormSelected$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.isContactFormSelected = this.bookingService.isContactFormSelected$.value
        if (this.isContactFormSelected)
          this.initContactFields()
      },
      error: (error) => this.adLoggerService.error(error)
    })
  }

  subscribeIsBookingInitialized() {
    if (this.subscriptionIsBookingInitialized)
      this.subscriptionIsBookingInitialized.unsubscribe()
    this.subscriptionIsBookingInitialized = this.bookingService.isBookingInitialized$.pipe(untilDestroyed(this))
      .subscribe((isBookingInitialized: boolean) => {
        if (isBookingInitialized)
          this.pediatricianMode = this.bookingService.otkUser?.fieldSettings?.pediatricianMode ?? false
      })
  }

  initContactFields() {
    const contactSettings = this.bookingService.otkUser.contactSettings;
    this.contactFormIssues = contactSettings.issues
    if (!contactSettings) { return }
    this.useSpecificFieldSettings = true
    const fieldSettings: FieldSettings = {
      basic: contactSettings.basic,
      custom: contactSettings.custom
    }
    this.createContactPersonalForm(fieldSettings, this.isZollsoftMode)
    this.manageNewForm()
  }

  baseLanguageSubscribe() {
    if (this.baseLanguageSubscription)
      this.baseLanguageSubscription.unsubscribe()
    this.baseLanguageSubscription = this.languageService.baseLanguageChangeSubject$
      .pipe(untilDestroyed(this),
        delay(500) // without this switching from French or Italian to German somehow shows text in Spanish
      )
      .subscribe(() => {
        this.baseLanguage = this.languageService.activeBaseLang
        this.baseLanguageDefault = this.languageService.DEFAULT_BASE_LANG
        this.info = this.appointmentType?.info
        this.changeDetectorRef.detectChanges()
        this.calendarCustomDateAdapter.setLocale(this.languageService.activeBaseLang)
      })
  }

  setLanguage() {
    this.calendarCustomDateAdapter.setLocale(this.languageService.activeBaseLang)
  }

  magicFill() {
    of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() =>
          setMagicFillValues(
            this.formGroup,
            this.changeDetectorRef,
            this.customFields,
            this.activeInstance
          )
        ),
        mergeMap(() => {
          this.changeDetectorRef.detectChanges()
          this.formGroup.updateValueAndValidity()
          return of(null)
        }),
        delay(500),
        mergeMap(() => {
          this.changeDetectorRef.detectChanges()
          this.formGroup.updateValueAndValidity()
          this.bookingService.stepper.next()
          return of(null)
        })
      )
      .subscribe()
  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscription(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionMagicFill)
        this.subscriptionMagicFill.unsubscribe()
      this.subscriptionMagicFill = this.bookingService.magicFill$
        .pipe(untilDestroyed(this))
        .subscribe((magicFill: string) =>
          magicFill === BookingStep.bookingPersonal ? this.magicFill() : null
        )
      subscriber.next()
      subscriber.complete()
    })
  }

  private subscribeToCustomerId() {
    if (this.isZollsoftMode) {
      if (this.subscriptionCustomerId)
        this.subscriptionCustomerId.unsubscribe()
      this.subscriptionCustomerId = this.activatedRoute.queryParams.subscribe((params) => {
        if (params?.customerId && this.formGroup.get('customerId')) {
          this.formGroup.get('customerId').setValue(params?.customerId)
        }
      })
    }
  }


  // gets pre-filled personal data from the app
  getPersonalDataFromApp(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      (window as any).flutter_inappwebview?.callHandler('getPersonalDataFromApp')
        .then((result) => {
          this.personalDataFromFlutter = JSON.parse(result)
          // define birthDate as follows:
          // if birthDate was set in booking-preliminary, use that value
          // else: if it was defined in the user profile in the app, use that value, but convert it to a Date, because it is given as a string (for instance "1989-12-31T23:00:00.000Z")
          // (by the way, if you console.log a Date object you get a different format, for instance "Mon Jan 01 1990 00:00:00 GMT+0100 (Mitteleuropäische Normalzeit)")
          // if not, just set it to null and don't add it to the rest of the data
          const birthDate: Date = this.bookingService.birthDate.value
            ? this.bookingService.birthDate.value
            : this.personalDataFromFlutter.birthDate
              ? new Date(this.personalDataFromFlutter.birthDate)
              : null
          // add birthDate if exists and emailConfirm
          this.personalDataFromFlutter = {
            ...this.personalDataFromFlutter,
            ...(birthDate && { birthDate }),
            emailConfirm: this.personalDataFromFlutter.email,
          }
          this.bookingService.personalFormComponent.formGroup.patchValue(this.personalDataFromFlutter)
        })
      subscriber.next()
      subscriber.complete()
    })
  }

  // If appointment type gets selected with specifiedFieldSettings
  // create new FormGroup
  // Needs only be done once the next stepper slot is selected but more convenient to do it
  // on appointmenttype value change
  recreateFormGroupWithCaching(specificFieldSettings?: FieldSettings, reducedForm = false) {
    this.pediatricianMode = specificFieldSettings ? specificFieldSettings.pediatricianMode : (this.bookingService.otkUser?.fieldSettings?.pediatricianMode ?? false)
    this.basicFields = {};
    this.customFields = []
    if (this.personalFormCachingSubscription)
      this.personalFormCachingSubscription.unsubscribe()
    // newFormGroup
    this.basicFields = constructBasicConfig(this.bookingService.otkUser.fieldSettings.basic, specificFieldSettings?.basic)
    this.customFields = specificFieldSettings?.custom ?? this.bookingService.otkUser.fieldSettings.custom ?? [];
    this.formGroup = createPersonalFormGroup(
      this.activeInstance,
      this.basicFields,
      this.customFields,
      { zollsoftMode: this.isZollsoftMode, reducedForm: reducedForm }
    )
    this.subscribeToCustomerId()

    // personalFormCaching
    this.personalFormCachingSubscription = this.formHelpers.syncBookingPersonalWithLocalStorage(this.formGroup, "BOOKING-PERSONAL-FORM", this.unsubscribe$)
    // recreateFormGroupWithCaching deletes the values potentially previously filled in by the app
    if (this.personalDataFromFlutter)
      this.bookingService.personalFormComponent.formGroup.patchValue(this.personalDataFromFlutter)
    this.hardSetBirthdate()
  }

  private createContactPersonalForm(specificFieldSettings: FieldSettings, reducedForm = false) {
    if (this.personalFormCachingSubscription)
      this.personalFormCachingSubscription.unsubscribe()
    // init new FormGroup
    this.formGroup = maybeAddBirthDate(createDefaultPersonalFormGroup(), this.isZollsoftMode, reducedForm);
    const userFields = populateContactFormGroup(this.activeInstance, this.formGroup, specificFieldSettings)
    this.basicFields = userFields.basic;
    this.customFields = userFields.custom;
    // personalFormCaching
    this.personalFormCachingSubscription = this.formHelpers.syncBookingPersonalWithLocalStorage(this.formGroup, "BOOKING-PERSONAL-FORM", this.unsubscribe$)
  }

  private hardSetBirthdate() {
    if (this.presetBirthDate && !this.isZollsoftMode) {
      this.formGroup.get('birthDate')?.setValue(this.bookingService.birthDate.value);
    }
  }

  appointmentTypeSubscribe(): Observable<void> {

    // only subscribe once
    if (this.appointmentTypeSubscription != null) {
      return of(null)
    }

    // this solves the bug, that no file-upload is shown for Zuweiser appointments on production and testing [ADI-2659]
    if (this.bookingService.appointmentType.value?.fileUpload) {
      this.showFileUpload = this.bookingService.appointmentType.value?.fileUpload;
    }

    this.appointmentTypeSubscription = this.bookingService.appointmentType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: AppointmentType) => {
        this.appointmentType = value
        this.info = value?.info
        this.showInfo = value?.showInfo
        this.showFileUpload = !!this.appointmentType?.fileUpload

        this.hardSetBirthdate()
        if (value?.useSpecificFieldSettings && value?.specificFieldSettings) {
          this.useSpecificFieldSettings = true
          this.recreateFormGroupWithCaching(value.specificFieldSettings)
          this.manageNewForm()
        }
        else if (this.useSpecificFieldSettings) {
          this.useSpecificFieldSettings = false
          this.recreateFormGroupWithCaching()
          this.manageNewForm()
        }
      })

    // not really needed only to keep previous structure
    return of(null)
  }

  manageNewForm() {
    this.reinitAddressFields()
    this.managePersonalFormValueChangesSubscription()
    this.changeDetectorRef.detectChanges()
  }

  public toFormattedDate(iso: string) {
    const date = new Date(iso);
    console.log(date);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }

  managePersonalFormValueChangesSubscription() {
    if (this.emailValueChangesSubscription && !this.emailValueChangesSubscription.closed)
      this.emailValueChangesSubscription.unsubscribe()
    const emailControl = this.formGroup.get('email')
    if (emailControl)
      this.emailValueChangesSubscription = emailControl.valueChanges
        .pipe(untilDestroyed(this), debounceTime(500))
        .subscribe(
          {
            next: (email: string) => {
              this.formGroup.get('emailConfirm').updateValueAndValidity()
              const emailTrim = email.trim()
              if (email !== emailTrim) emailControl.setValue(emailTrim)
            }
          })

    if (this.personalFormValueChangesSubscription && !this.personalFormValueChangesSubscription.closed)
      this.personalFormValueChangesSubscription.unsubscribe()
    this.personalFormValueChangesSubscription = this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.bookingService.personalFormChanges$.next(null))
  }

  reinitAddressFields() {
    this.addressVisible = false
    this.changeDetectorRef.detectChanges()
    this.addressVisible = true
    this.changeDetectorRef.detectChanges()
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "mat-form-field.mat-form-field-invalid, mat-checkbox.ng-invalid"
    );
    const invalidCheckbox = firstInvalidControl?.tagName === 'MAT-CHECKBOX'
    const targetAnimationElement = firstInvalidControl?.firstElementChild?.firstElementChild
    const targetScrollElement = invalidCheckbox ? firstInvalidControl?.firstElementChild?.firstElementChild?.nextElementSibling : targetAnimationElement

    if (targetAnimationElement && targetScrollElement) {
      targetAnimationElement.classList.remove("invalid-animation")
      targetScrollElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' }); // smooth behavior
      targetAnimationElement.classList.add("invalid-animation")
    }
    setTimeout(() => this.resetAnimationClass(), 1000)
  }

  resetAnimationClass() {
    const elementsWithInvalidClass: HTMLElement[] = Array.from(this.el.nativeElement.getElementsByClassName('invalid-animation'))

    if (elementsWithInvalidClass && elementsWithInvalidClass?.length) {
      elementsWithInvalidClass.forEach(ele => {
        ele.classList.remove("invalid-animation")
      })
    }
  }

  handleNextButton(): boolean {
    if (this.isFormGroupValid()) return false
    this.formGroup.markAllAsTouched();
    WfaModule.WfaFormGroupService.updateValueAndValidity(this.formGroup);
    this.changeDetectorRef.detectChanges()
    this.scrollToFirstInvalidControl();
    return true
  }

  clickToDataProtection(e: MouseEvent) {
    if ((e.target as Element)?.id === 'data-protection-link') { e.preventDefault(); this.legalService.openDialog(this.Legal.Datenschutz) }
  }


  /**
   * Return one of the errors of the specified FormControl.
   *  
   * @returns the key of the first ValidationError
   *    or '' (empty string) when there are no errors
   */
  public getErrorKey(formControlName: string): string {
    const control: AbstractControl = this.formGroup.get(formControlName);
    const errors = control.errors;
    if (!errors) return '';
    const errorKeys = Object.keys(errors);
    if (!errorKeys || errorKeys.length < 1) return '';
    return errorKeys[0]
  }

}
