import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { WfaEnvService } from '../../../environments/wfa-env.service'
import { PraxisId } from '../../../schemas/interfaces'
import { MatInputPopupComponent } from './../../utility-components/mat-input-popup.component'
import { FormDraftTableComponent } from './form-draft-table/form-draft-table.component'
import { FormTableComponent } from './form-table/form-table.component'
import { PraxisSelectComponent } from './praxis-select.component'
import { WfaUnlockFormComponent } from './unlock-form/unlock-form.component'
import { ZsSupportService } from './zs-support.service'
import { ZsSupportCommsService } from './zs-support-comms.service'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-zs-support',
  template: `
    @if (showSpinner) {
      <div class="overlay"><mat-spinner></mat-spinner></div>
    }
    <wfa-praxis-select [praxisId]="praxisId"></wfa-praxis-select>
    <wfa-form-table></wfa-form-table>
    <wfa-form-draft-table></wfa-form-draft-table>
    <wfa-mat-input-popup [params]="zsSupportService.editWfaFormIdModal">
    </wfa-mat-input-popup>
    <wfa-unlock-form></wfa-unlock-form>
  `,
  imports: [
    CommonModule,
    FormDraftTableComponent,
    FormTableComponent,
    HttpClientModule,
    MatInputPopupComponent,
    MatProgressSpinnerModule,
    PraxisSelectComponent,
    WfaUnlockFormComponent,
  ],
  providers: [ZsSupportService, ZsSupportCommsService],
  styleUrls: ['./zs-support.component.scss'],
})
export class ZsSupportComponent implements OnInit {
  @Input() praxisId: PraxisId | undefined

  showSpinner = false

  constructor(
    public wfaEnvService: WfaEnvService,
    public zsSupportService: ZsSupportService,
  ) {}

  ngOnInit() {
    this.wfaEnvService.editorEnv$.next({
      ...this.wfaEnvService.editorEnv$.value,
      origin: 'zsSupport',
    })

    this.zsSupportService.showSpinner$
      .pipe(untilDestroyed(this))
      .subscribe((_showSpinner) => {
        this.showSpinner = _showSpinner
      })
  }
}
