import Joi from 'joi'

import { WfaForm } from '../schemas/interfaces'
import { wfaFormJoiDict } from '../schemas/joi/wfa-form.joi'
import { Maybe } from './../types/maybe'
import { newWfaMessage, WfaMessage } from './../types/wfa-message'
import { nullish } from './nullish'
import { selectVersion } from './select-version'
import { validate } from './validate'

export function validateWfaForm(form: Maybe<unknown>): WfaForm | WfaMessage {
  if (nullish(form))
    return newWfaMessage('[validateWfaForm]: no message provided')
  const wfaVersion = (form as WfaForm).wfaVersion ?? 'v1.5.0'
  const wfaFormJoi =
    selectVersion(wfaVersion, wfaFormJoiDict) ??
    (wfaFormJoiDict['v1.5.0'] as unknown as Joi.ObjectSchema<WfaForm>)
  return validate(wfaFormJoi, form) as unknown as WfaForm | WfaMessage
}
