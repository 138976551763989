import { CommonModule } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { UntilDestroy } from '@ngneat/until-destroy'
import { BehaviorSubject, filter, switchMap, tap } from 'rxjs'

import { json } from '../../../assets/json/json.barrel'
import { testing } from '../../../assets/json/testing'
import { zollsoftForms } from '../../../assets/json/zollsoft/_zollsoft-forms.barrel'
import exampleSurveyjsResponse from '../../../assets/json/zollsoft/personal-data.example-reply.json'
import { dummyPersonalDataWfaFormResponse } from '../../../schemas/defaults/wfa-form-response.default'
import { ViewerLoadingState } from '../../../schemas/interfaces'
import { CoreLocale } from '../../../schemas/interfaces/core-locale'
import { DisplayOption } from '../../../schemas/interfaces/display-option'
import { FormResponseData } from '../../../schemas/interfaces/form-response-data'
import { ViewerEnvState } from '../../../schemas/interfaces/viewer-env-state'
import { WfaForm } from '../../../schemas/interfaces/wfa-form'
import { WfaFormResponse } from '../../../schemas/interfaces/wfa-form-response'
import { displayOptions } from '../../../schemas/joi/display-option.joi'
import { Maybe } from '../../../types/maybe'
import { notNullish, nullish } from '../../../utility/nullish'
import { UserMessageComponent } from '../../user-message/user-message.component'
import submitting from '../../user-message/viewer-from-link-submitting.json'
import successfullySubmitted from '../../user-message/viewer-from-link-successfully-submitted.json'
import { checkFormResponseOrigin } from '../../utility/check-form-response-origin'
import { get } from '../../utility/lodash-alternatives/get'
import { submitFormResponseData } from '../../utility/submit-form-response-data'
import { updateState } from '../../utility/update-state'
import { generateFormResponse } from '../../viewer/utility/generate-form-response/generate-form-response'
import { ViewerComponent } from '../../viewer/viewer.component'
import { WfaEnvService } from './../../../environments/wfa-env.service'
import { getIsEmbeddedIn } from './../../../schemas/joi/is-embedded-in.joi'
import { defaultViewerEnvState } from './../../../schemas/utils/viewer-env-state.utils'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-test-viewer',
  templateUrl: './test-viewer.component.html',
  styleUrls: [
    '../overlay.scss',
    './test-viewer.component.scss',
    '../../viewer/viewer-parent.scss',
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ViewerComponent,
    FormsModule,
    UserMessageComponent,
  ],
})
export class TestViewerComponent implements OnInit {
  envState$ = new BehaviorSubject<ViewerEnvState>(defaultViewerEnvState)
  form$ = new BehaviorSubject<Maybe<WfaForm>>(zollsoftForms.personalData)
  formResponse$ = new BehaviorSubject<Maybe<WfaFormResponse>>({
    ...dummyPersonalDataWfaFormResponse,
    surveyjsResponse: exampleSurveyjsResponse,
  })
  praxisId = dummyPersonalDataWfaFormResponse.praxisId
  responseId = dummyPersonalDataWfaFormResponse.responseId
  wfaFormId = dummyPersonalDataWfaFormResponse.wfaFormId
  wfaFormVersionId = dummyPersonalDataWfaFormResponse.wfaFormVersionId
  status: ViewerLoadingState = 'survey'
  submittingJson = submitting
  submittedJson = successfullySubmitted

  title = 'webforms-angular'
  locales = ['de', 'en']
  jsons = { ...json.hnoMedicUnkelbach, ...zollsoftForms, ...testing }
  displayOptions = displayOptions
  localeControl = new FormControl('de')
  jsonControl = new FormControl('personalData')
  displayOptionControl = new FormControl('editable')
  showOverlay = false
  emitFormResponseData$ = new BehaviorSubject<Maybe<FormResponseData>>(null)

  updateFormResponseSettings() {
    const praxisId = this.praxisId ?? dummyPersonalDataWfaFormResponse.praxisId
    const responseId =
      this.responseId ?? dummyPersonalDataWfaFormResponse.responseId
    const wfaFormId =
      this.wfaFormId ?? dummyPersonalDataWfaFormResponse.wfaFormId
    const wfaFormVersionId =
      this.formResponse$.value?.wfaFormVersionId ??
      this.wfaFormVersionId ??
      dummyPersonalDataWfaFormResponse.wfaFormVersionId
    const surveyjsResponse = this.formResponse$.value?.surveyjsResponse ?? {}

    const formResponse: WfaFormResponse = {
      ...this.formResponse$.value,
      praxisId,
      responseId,
      wfaFormId,
      wfaFormVersionId,
      surveyjsResponse,
    }
    this.formResponse$.next(formResponse)
  }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private wfaEnvService: WfaEnvService,
  ) {}

  ngOnInit(): void {
    {
      const isEmbeddedIn = getIsEmbeddedIn(this.wfaEnvService.targetBuild)
      const env = { ...this.envState$.value, isEmbeddedIn }
      updateState(env, this.envState$)
    }

    this.localeControl.valueChanges.subscribe((newLocale) => {
      const locale = newLocale as CoreLocale
      const env = { ...this.envState$.value, locale }
      updateState(env, this.envState$)
    })

    this.jsonControl.valueChanges.subscribe((json) => {
      const maybeAsModule = get(this.jsons, json as string)
      const form = maybeAsModule?.default ?? maybeAsModule
      updateState(form, this.form$)
    })

    this.displayOptionControl.valueChanges.subscribe((newDisplayOption) => {
      const displayOption = newDisplayOption as DisplayOption
      const env = { ...this.envState$.value, displayOption }
      this.envState$.next(env)
    })

    this.emitFormResponseData$
      .pipe(
        filter(notNullish),
        filter((formResponseData) =>
          checkFormResponseOrigin(formResponseData, ['completeButton']),
        ),
        tap(() => {
          if (nullish(get(this.form$, 'value.completedHtml'))) {
            this.status = 'submitting'
          }
        }),
        switchMap((formResponseData) =>
          submitFormResponseData(
            this.form$.value,
            formResponseData as FormResponseData,
            this.httpClient,
            this.wfaEnvService,
            this.router,
          ),
        ),
        tap(() => {
          if (nullish(get(this.form$, 'value.completedHtml'))) {
            this.status = 'submitted'
          }
        }),
      )
      .subscribe()
  }

  magicFill(): void {
    const currentForm = get(this.jsons, this.jsonControl.value as string)
    const surveyjsResponse = generateFormResponse(currentForm)
    const wfaFormResponse = { ...this.formResponse$.value, surveyjsResponse }
    this.formResponse$.next(wfaFormResponse as unknown as WfaFormResponse)
  }
}
