import Joi from 'joi'

import { zollsoftForms } from '../../../assets/json/zollsoft/_zollsoft-forms.barrel'
import { wfaVersion } from '../../../wfa-version'
import { staticIdDict } from '../../../wfb/populate-test-db/praxis-id.dict.test-db'
import { WfaForm_v2_9_0 } from '../../interfaces/wfa-form-versions/wfa-form-v2-9-0'
import { uniqueNamesValidator } from '../../joi-custom-validation/unique-names-validator'
import { CertificateJoi } from '../certificate.joi'
import { CoreLocaleJoi } from '../core-locale.joi'
import { IdJoi } from '../id.joi'
import { LocStringJoi } from '../loc-string.joi'
import { PraxisIdJoi } from '../praxis-id.joi'
import { RelaxedWfaFormIdJoi } from '../wfa-form-id.joi'
import { wfaFormStatusDefault, WfaFormStatusJoi } from '../wfa-form-status.joi'
import { WfaPageJoi } from '../wfa-page.joi'

export const wfaFormDefault = {
  praxisId: staticIdDict.staticZsLib,
  instance: staticIdDict.staticZsLib,
  status: wfaFormStatusDefault,
  wfaVersion,
}

export const WfaFormJoi: Joi.ObjectSchema<WfaForm_v2_9_0> = Joi.object()
  .keys({
    certificate: CertificateJoi.optional(),
    description: LocStringJoi.optional(),
    instance: PraxisIdJoi.required().valid(Joi.ref('praxisId')),
    locale: CoreLocaleJoi.optional().description(
      'Currently necessary for legacy arzt-direkt settings.',
    ),
    permalink: Joi.string().allow(null).optional(),
    praxisId: PraxisIdJoi.required(),
    pages: Joi.array().items(WfaPageJoi),
    status: WfaFormStatusJoi.required(),
    title: LocStringJoi.required(),
    wfaFormId: RelaxedWfaFormIdJoi.required(),
    wfaFormVersionId: IdJoi.required(),
    wfaVersion: Joi.string().required(),
    _id: IdJoi.valid(Joi.ref('wfaFormVersionId')).optional(),
  })
  .custom(uniqueNamesValidator, 'uniqueNames')
  .unknown(true)
  .description(
    `
    Schema for validation of JSON created by the SurveyJS editor.
    SurveyJS does not provide a validation of its own, that is why this schema here was created.
    Accordingly, this Joi schema must be considered with every update in relation to the editor!
    `,
  )
  .meta({
    className: 'WfaForm_v2_9_0',
  })
  .example(zollsoftForms.personalData)
