import { TranslateService } from '@ngx-translate/core'

import de from '../../assets/i18n/de.json'
import en from '../../assets/i18n/en.json'
import es from '../../assets/i18n/es.json'
import fr from '../../assets/i18n/fr.json'
import it from '../../assets/i18n/it.json'
import { CoreLocale } from '../../schemas/interfaces/core-locale'

const defaultLang = 'de'
const dict = { de, en, es, fr, it }

export function translateMatDialogContent(
  translate: TranslateService,
  locale?: CoreLocale,
) {
  const lang = locale ?? defaultLang
  const translation = dict[lang] ?? dict[defaultLang]
  translate.currentLang = lang
  translate.setTranslation(lang, translation, true)
}
