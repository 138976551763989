import { CoreLocale } from '../../../../schemas/interfaces/core-locale'
import { defaultLocale } from '../../default-locale'

type Category =
  | 'uploadError'
  | 'readFileError'
  | 'fileTypeError'
  | 'fileSizeError'

type ErrorMessageDict = Record<Category, Record<CoreLocale, string>>

interface ErrorContext {
  name?: string
  type?: string
  maxMegaByteSize?: number
  errorMessage?: string
}

export function getLocalizedErrorMessage(
  locale: CoreLocale,
  category: Category,
  errorContext: ErrorContext,
): string {
  const { name, type, maxMegaByteSize, errorMessage } = errorContext
  const errorMessageDict: ErrorMessageDict = {
    uploadError: {
      de: `Fehler beim Upload: ${errorMessage}`,
      en: `Error during upload: ${errorMessage}`,
      es: `Error durante la carga: ${errorMessage}`,
      fr: `Erreur lors du téléchargement: ${errorMessage}`,
      it: `Errore durante l'upload: ${errorMessage}`,
    },
    readFileError: {
      de: `Die Datei ${name} konnte nicht eingelesen werden.`,
      en: `The file ${name} could not be read.`,
      es: `No se ha podido leer el archivo ${name} en.`,
      fr: `Le fichier ${name} n'a pas pu être lu.`,
      it: `Non è stato possibile leggere il file ${name} in.`,
    },
    fileTypeError: {
      de: `Sie dürfen die Datei ${name} vom Typ ${type} nicht hochladen.`,
      en: `You may not upload the file ${name} of type ${type}.`,
      es: `No puede cargar el archivo ${name} de tipo ${type}.`,
      fr: `Vous ne pouvez pas télécharger le fichier ${name} de type ${type}.`,
      it: `Non è possibile caricare il file ${name} di tipo ${type}.`,
    },
    fileSizeError: {
      de: `Sie dürfen maximal ${maxMegaByteSize} MB hochladen. Die Datei ${name} ist zu groß.`,
      en: `You may upload a maximum of ${maxMegaByteSize} MB. The file ${name} is too large.`,
      es: `Puede cargar un máximo de ${maxMegaByteSize} MB. El archivo ${name} es demasiado grande.`,
      fr: `Vous pouvez télécharger un maximum de ${maxMegaByteSize} MB. Le fichier ${name} est trop volumineux.`,
      it: `È possibile caricare un massimo di ${maxMegaByteSize} MB. Il file ${name} è troppo grande.`,
    },
  }
  return (
    errorMessageDict[category][locale] ??
    errorMessageDict[category][defaultLocale]
  )
}
