import { Question } from 'survey-core'
import { SurveyCreatorModel } from 'survey-creator-core'

import { CoreLocale, WfaKey } from '../../../../schemas/interfaces'
import { notNullish, nullish } from '../../../../utility/nullish'
import { initialEditorState } from '../../editor.state'
import { generateUniqueQuestionName } from './generate-unique-question-name'
import { isWfaKey } from './wfa-key/wfa-key-utils'

const defaultLocale = initialEditorState.editorEnv.locale

const duplicateValueMessage = {
  de: 'Fehler: dieser Wert wird bereits verwendet.',
  en: 'Error: this value is already in use.',
  es: 'Error: este valor ya está en uso.',
  fr: 'Erreur : cette valeur est déjà utilisée.',
  it: 'Errore: questo valore è già in uso.',
}

export function validatePropertiesOnValueChange(
  creator: SurveyCreatorModel,
): void {
  creator.onPropertyValidationCustomError.add((creator, options) => {
    if (options.propertyName === 'wfaKey') {
      const wfaKey = options.value
      const question = options.obj as Question

      if (nullish(wfaKey) && isWfaKey(question.name))
        question.name = generateUniqueQuestionName(creator)

      if (notNullish(wfaKey) && isWfaKeyUnique(creator, wfaKey) === false) {
        const locale = creator.locale as CoreLocale
        options.error = getLocalizedErrorMessage(locale)
      }
    }
  })
}

function isWfaKeyUnique(
  creator: SurveyCreatorModel,
  selectedWfaKey: WfaKey,
): boolean {
  const questions = creator.survey.getAllQuestions()
  const wfaKeys = questions
    .map((question) => question.name)
    .filter((name) => name === selectedWfaKey)

  if (wfaKeys.length > 1) return false
  return true
}

function getLocalizedErrorMessage(locale: CoreLocale): string {
  return duplicateValueMessage[locale] ?? duplicateValueMessage[defaultLocale]
}
