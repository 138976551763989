import Joi from 'joi'

import { ClearIfInvisible } from '../../../interfaces/wfa-question/base-question/clear-if-invisible'

export const clearIfInvisibleDict: Record<string, ClearIfInvisible> = {
  default: 'default',
  onHidden: 'onHidden',
  onHiddenContainer: 'onHiddenContainer',
  onComplete: 'onComplete',
  none: 'none',
} as const

export const clearIfInvisibles = Object.values(clearIfInvisibleDict)

export const ClearIfInvisibleJoi = Joi.string()
  .valid(...clearIfInvisibles)
  .meta({
    className: 'ClearIfInvisible',
  })
  .example('default')
