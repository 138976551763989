import { Maybe } from '../../../types/maybe'

export function set<T>(obj: T, path: string | string[], value: any): T

/* from https://youmightnotneed.com/lodash */
export function set(
  obj: Record<string, any>,
  path: string | string[],
  value: any,
): Maybe<Record<string, any>> {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

  if (pathArray == null) return undefined
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {}
    if (i === pathArray.length - 1) acc[key] = value
    return acc[key]
  }, obj)

  return obj
}
