import { SurveyModel, ValidateQuestionEvent } from 'survey-core'

import { CoreLocale } from '../../../../../schemas/interfaces/core-locale'
import { defaultLocale } from '../../../default-locale'

export function validatePassword(
  surveyModel: SurveyModel,
  options: ValidateQuestionEvent,
) {
  const question = options.question
  if (question.getType() !== 'text' || question['inputType'] !== 'password')
    return

  const input = options.value
  if (input !== 'topsecret') {
    const locale = surveyModel.locale as CoreLocale
    options.error = getLocalizedErrorMessage(locale)
  }
}

function getLocalizedErrorMessage(locale: CoreLocale): string {
  const errorMessageDict: Record<CoreLocale, string> = {
    de: 'Passwort ungültig.',
    en: 'Invalid password.',
    es: 'Contraseña inválida.',
    fr: 'Mot de passe non valide.',
    it: 'Password non valida.',
  }
  return errorMessageDict[locale] ?? errorMessageDict[defaultLocale]
}
