import { ViewerEnvState } from '../interfaces/viewer-env-state'
import { WebviewAction } from '../interfaces/webview-viewer/webview-action'
import { WfaForm } from '../interfaces/wfa-form'
import { WfaFormResponse } from '../interfaces/wfa-form-response'
import { WfaFormWithResponse } from '../interfaces/wfa-form-with-response'
import { initialViewerEnvStateDict } from './viewer-env-state.utils'

export interface FormFromTomedo {
  action: WebviewAction & 'formFromTomedo'
  formData: WfaFormWithResponse
  envState?: ViewerEnvState
}

export interface ExportPDF {
  action: WebviewAction & 'exportPDF'
}

export interface CompleteForm {
  action: WebviewAction & 'completeForm'
}

export interface StatusRequestedByTomedo {
  action: WebviewAction & 'statusRequestedByTomedo'
}

export interface SubmitStatusToTomedo {
  action: WebviewAction & 'submitStatusToTomedo'
}

export function isFormFromTomedo(x: unknown): x is FormFromTomedo {
  const dataFromTomedo = x as FormFromTomedo
  return (
    typeof dataFromTomedo === 'object' &&
    dataFromTomedo.action === 'formFromTomedo' &&
    dataFromTomedo.formData &&
    typeof dataFromTomedo.formData.form === 'object'
  )
}

export function isExportPDF(x: unknown): x is ExportPDF {
  const dataFromTomedo = x as ExportPDF
  return (
    typeof dataFromTomedo === 'object' && dataFromTomedo.action === 'exportPDF'
  )
}

export function isCompleteForm(x: unknown): x is CompleteForm {
  const dataFromTomedo = x as CompleteForm
  return (
    typeof dataFromTomedo === 'object' &&
    dataFromTomedo.action === 'completeForm'
  )
}

export function isStatusRequestedByTomedo(
  x: unknown,
): x is StatusRequestedByTomedo {
  const dataFromTomedo = x as StatusRequestedByTomedo
  return (
    typeof dataFromTomedo === 'object' &&
    dataFromTomedo.action === 'statusRequestedByTomedo'
  )
}

export function isSubmitStatusToTomedo(x: unknown): x is SubmitStatusToTomedo {
  const dataFromTomedo = x as SubmitStatusToTomedo
  return (
    typeof dataFromTomedo === 'object' &&
    dataFromTomedo.action === 'submitStatusToTomedo'
  )
}

export function wrapFormToWebviewInputData(
  form: WfaForm,
  formResponse: WfaFormResponse,
  action: 'formFromTomedo',
  envState?: ViewerEnvState,
): FormFromTomedo {
  return {
    action,
    formData: {
      form,
      formResponse,
    },
    envState: envState ?? initialViewerEnvStateDict['iOsTomedo'],
  }
}
