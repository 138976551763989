import { catchError, of } from 'rxjs'

import { Routing } from '../../../interfaces'
import { Id } from './../../../../../schemas/interfaces/id'
import { WfaForm } from './../../../../../schemas/interfaces/wfa-form'
import { Maybe } from './../../../../../types/maybe'
import { isWfaMessage, newWfaMessage } from './../../../../../types/wfa-message'
import { nullish } from './../../../../../utility/nullish'
import { getUrl } from './../../../../utility/get-url'
import { httpOptions } from './../../../../utility/http-params'
import { AreYouSureUserInput } from './../../../../utility-components/are-you-sure.component'
import { AdisEditorEnv } from './../../../editor.env'
import { navigateToEditorOrigin } from './navigate-to-editor-origin'

export function deleteDraftUsingForm(
  wfaForm: Maybe<WfaForm>,
  routing: Routing<AdisEditorEnv>,
) {
  if (nullish(wfaForm)) {
    console.error(`[publishDraft]: nullish wfaFormVersionId`)
    return
  }
  deleteDraft(wfaForm.wfaFormVersionId, routing)
}

function deleteDraft(wfaFormVersionId: Id, routing: Routing<AdisEditorEnv>) {
  routing.staticEditorEnv.deleteDraftModal.userInput$.subscribe(
    (input: AreYouSureUserInput) => {
      if (input !== 'yes') {
        routing.staticEditorEnv.deleteDraftModal.show$.next(false)
      }

      const url = getUrl(routing.wfaEnvService, `wfaFormDraft/delete`)
      const body = {
        wfaFormVersionId,
      }

      routing.httpClient
        .post(url, body, httpOptions)
        .pipe(
          catchError((error) => {
            routing.wfaEnvService.logger.error(
              `[deleteDraft]: called post with url ${url}, got error `,
              error,
              `for wfaFormVersionId`,
              wfaFormVersionId,
            )
            return of(newWfaMessage(`[deleteDraft]: ${error?.message}`))
          }),
        )
        .subscribe((res: unknown) => {
          if (!isWfaMessage(res)) {
            navigateToEditorOrigin(routing)
          }
        })
    },
  )

  routing.staticEditorEnv.deleteDraftModal.show$.next(true)
}
