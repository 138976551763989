import { WfaForm } from '../../../../schemas/interfaces/wfa-form'
import { elementIsPanel } from '../../../../utility/get-questions'
import { notNullish, nullish } from '../../../../utility/nullish'
import { get } from '../../../utility/lodash-alternatives/get'
import { set } from '../../../utility/lodash-alternatives/set'
import { SurveyJsElement, WfaPage } from '../../types/index'
import { SurveyJsItem } from '../../types/index'
import { WfaQuestion } from './../../../../schemas/interfaces/wfa-question/wfa-question'

const nonInteractiveQuestionTypes = ['html', 'expression']

export function cleanFormForReadOnlyMode(
  wfaForm: WfaForm,
  hideFileUpload = true,
): WfaForm {
  const wfaPages: WfaPage[] = get(wfaForm, 'pages')
  if (nullish(wfaPages)) return wfaForm
  const cleanedPages = wfaPages.map((page) => cleanPage(page, hideFileUpload))
  return set(wfaForm, 'pages', cleanedPages) as WfaForm
}

const cleanPage = (wfaPage: WfaPage, hideFileUpload: boolean) => {
  return cleanSurveyItem(wfaPage, hideFileUpload)
}

function cleanSurveyItem(
  surveyItem: SurveyJsItem,
  hideFileUpload: boolean,
): SurveyJsItem {
  const elements: SurveyJsElement[] = get(surveyItem, 'elements')
  if (nullish(elements)) return surveyItem
  const cleanedElements = elements.map((element) => {
    if (elementIsPanel(element)) return cleanSurveyItem(element, hideFileUpload)
    return cleanQuestion(element as WfaQuestion, hideFileUpload)
  })

  return set(surveyItem, 'elements', cleanedElements) as SurveyJsItem
}

function cleanQuestion(
  wfaQuestion: WfaQuestion,
  hideFileUpload: boolean,
): WfaQuestion {
  if (
    nonInteractiveQuestionTypes.includes(wfaQuestion.type) ||
    (hideFileUpload && wfaQuestion.type === 'file')
  )
    wfaQuestion['visible'] = false
  if (notNullish(wfaQuestion['description']))
    wfaQuestion['description'] = undefined
  return wfaQuestion
}
