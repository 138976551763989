import { Model } from 'survey-core'
import {
  DrawCanvas,
  HorizontalAlign,
  SurveyPDF,
  VerticalAlign,
} from 'survey-pdf'

import { CoreLocale, SurveyjsResponse } from '../../../schemas/interfaces'
import { WfaForm } from '../../../schemas/interfaces/wfa-form'
import { Maybe } from '../../../types/maybe'
import { notNullish } from '../../../utility/nullish'
import { initialViewerState } from '../viewer.state'
import { formatDate } from './format-date'
import { formatTitleToFileName } from './format-title-to-file-name'
import pdfOptions from './pdf-options.json'
const defaultLocale = initialViewerState.env.locale

export function exportSurveyToPdf(
  surveyModel: Model,
  submittedAt: Maybe<number>,
): void {
  const surveyJson = surveyModel.toJSON() as WfaForm
  const surveyPdf = new SurveyPDF(surveyJson, pdfOptions as unknown as any)
  const locale = (
    surveyModel.locale.length > 0 ? surveyModel.locale : defaultLocale
  ) as CoreLocale
  const response = surveyModel.data
  const submitDate = submittedAt ? new Date(submittedAt) : new Date()

  configureSurveyPdf(locale, response, surveyPdf, submitDate)
  const fileName = formatTitleToFileName(locale, surveyJson, submitDate)
  surveyPdf.save(fileName)
}

/**
 * @param surveyPdf is mutated
 */
function configureSurveyPdf(
  locale: CoreLocale,
  response: SurveyjsResponse,
  surveyPdf: SurveyPDF,
  submitDate: Date,
): void {
  surveyPdf.data = response
  surveyPdf.locale = locale
  surveyPdf.questionsOnPageMode = 'singlePage'
  surveyPdf.mode = 'display'
  configureQuestionPdf(surveyPdf)
  surveyPdf.onRenderHeader.add((_, canvas) =>
    renderSubmitDate(canvas, submitDate),
  )
  surveyPdf.onRenderFooter.add(renderPageNumber)
  surveyPdf.onRenderFooter.add(renderPatientInfo)
}

function configureQuestionPdf(surveyPdf: SurveyPDF): void {
  surveyPdf.getAllQuestions().forEach((question) => {
    const type = question.getType()
    if (type === 'birthdate' || question['inputType'] === 'date')
      question.value = formatDate(question.value, '.')
    if (type === 'file') question['imageWidth'] = '250px'
  })
}

function renderPageNumber(_: SurveyPDF, canvas: DrawCanvas): void {
  canvas.drawText({
    text: `${canvas.pageNumber}/${canvas.pageCount}`,
    fontSize: 10,
    horizontalAlign: 'right' as HorizontalAlign,
    verticalAlign: 'bottom' as VerticalAlign,
    margins: {
      right: 20,
      bot: 12,
    },
  })
}

function renderPatientInfo(surveyPdf: SurveyPDF, canvas: DrawCanvas): void {
  const { $wfaPatientVorname$, $wfaPatientNachname$, $wfaPatientGeburtstag$ } =
    surveyPdf.data

  const infoItems = [
    $wfaPatientNachname$,
    $wfaPatientVorname$,
    $wfaPatientGeburtstag$,
  ]

  if (infoItems.every(notNullish)) {
    const patientInfo = `${infoItems[0]}, ${infoItems[1]} geb. ${infoItems[2]}`
    canvas.drawText({
      text: patientInfo,
      fontSize: 10,
      horizontalAlign: 'left' as HorizontalAlign,
      verticalAlign: 'bottom' as VerticalAlign,
      margins: {
        left: 25,
        bot: 12,
      },
    })
  }
}

function renderSubmitDate(canvas: DrawCanvas, submitDate: Date) {
  const formattedSubmitDate = formatDate(submitDate, '.') as string
  canvas.drawText({
    text: `Datum: ${formattedSubmitDate}`,
    fontSize: 10,
    horizontalAlign: 'right' as HorizontalAlign,
    verticalAlign: 'middle' as VerticalAlign,
    margins: {
      right: 20,
      bot: 12,
    },
  })
}
