import { IQuestionToolboxItem, SurveyCreatorModel } from 'survey-creator-core'

import { notNullish, nullish } from '../../../../../utility/nullish'

type SimpleDefaultValue = string

interface ExtendedDefaultValue {
  value?: string
  name?: string
  [x: string]: unknown
}

type DefaultValue = SimpleDefaultValue | ExtendedDefaultValue

const questionsWithDefaults = [
  {
    type: 'checkbox',
    propsWithDefaults: ['choices'],
  },
  {
    type: 'radiogroup',
    propsWithDefaults: ['choices'],
  },
  {
    type: 'dropdown',
    propsWithDefaults: ['choices'],
  },
  {
    type: 'tagbox',
    propsWithDefaults: ['choices'],
  },
  {
    type: 'ranking',
    propsWithDefaults: ['choices'],
  },
  {
    type: 'imagepicker',
    propsWithDefaults: ['choices'],
  },
  {
    type: 'matrix',
    propsWithDefaults: ['rows', 'columns'],
  },
  {
    type: 'matrixdropdown',
    propsWithDefaults: ['choices', 'rows', 'columns'],
  },
  {
    type: 'matrixdynamic',
    propsWithDefaults: ['choices', 'columns'],
  },
]

export function customizeDefaults(creator: SurveyCreatorModel): void {
  questionsWithDefaults.forEach((question) => {
    const questionToolboxItem = creator.toolbox.getItemByName(question.type)
    if (nullish(questionToolboxItem)) return
    customizeQuestionDefaults(questionToolboxItem, question.propsWithDefaults)
  })
}

function customizeQuestionDefaults(
  questionToolboxItem: IQuestionToolboxItem,
  propsWithDefaults: string[],
): void {
  propsWithDefaults.forEach((property) => {
    const propertyDefaultValues = questionToolboxItem.json[
      property
    ] as DefaultValue[]
    questionToolboxItem.json[property] = propertyDefaultValues.map(
      (defaultValue) => {
        if (typeof defaultValue === 'string') return removeSpaces(defaultValue)
        if (notNullish(defaultValue.value))
          return {
            ...defaultValue,
            value: removeSpaces(defaultValue.value),
          }
        if (notNullish(defaultValue.name))
          return {
            ...defaultValue,
            name: removeSpaces(defaultValue.name),
          }
        return defaultValue
      },
    )
  })
}

function removeSpaces(s: string) {
  return s.replace(/\s/g, '')
}
