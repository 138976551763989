import { CustomPropertyInfo } from '../../../../../schemas/interfaces/custom-property-info'

const instance: CustomPropertyInfo = {
  name: 'instance',
  type: 'string',
  visible: false,
}

const surveyElements = ['survey']

export const surveyElementsWithInstance = surveyElements.reduce(
  (acc, item) => {
    acc[item] = instance
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
