import Joi from 'joi'

import { EditorOrigin } from './../../../interfaces/app/editor/editor-origin'

export const editorOriginDict: Record<string, EditorOrigin> = {
  doctorFormTableManagement: 'doctorFormTableManagement',
  orphan: 'orphan',
  zsSupport: 'zsSupport',
} as const

export const editorOrigins = Object.values(editorOriginDict)
export const editorOriginDefault = editorOriginDict['orphan']

export const EditorOriginJoi = Joi.string()
  .valid(...editorOrigins)
  .default(editorOriginDefault)
  .description(
    `Identifies from which location / component the editor was opened.
 - doctorFormTableManagement: opened from DoctorFormTableManagementComponent
 - orphan: was opened directly using a route (e.g. from test-editor-route)
 - zsSupport: opened from ZsSupportComponent
`,
  )
  .meta({
    className: 'EditorOrigin',
  })
  .example('zsSupport')

export function isEditorOrigin(x: unknown): x is EditorOrigin {
  return editorOrigins.includes(x as EditorOrigin)
}
