import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest, map } from 'rxjs'
import { setDocumentStyles } from 'shared-assets/vendor/ad-colors/src'

import { CoreLocale } from '../../../schemas/interfaces/core-locale'
import { EditorComponent } from '../../editor/editor.component'
import { LicenseTier } from '../../types/license-tier'
import { emitDefaultAndSanitize } from '../../utility/sanitize-control'
import { EditorEnv } from './../../editor/editor.env'
import { STATIC_EDITOR_ENV, testEditorEnv } from './../../editor/editor.env'

@UntilDestroy()
@Component({
  selector: 'app-test-editor',
  standalone: true,
  imports: [CommonModule, EditorComponent, ReactiveFormsModule],
  templateUrl: './test-editor.component.html',
  styleUrls: ['../overlay.scss', './test-editor.component.scss'],
  providers: [{ provide: STATIC_EDITOR_ENV, useValue: testEditorEnv }],
})
export class TestEditorComponent implements OnInit {
  locales = ['en', 'de', 'fr', 'it', 'es']
  localeControl = new FormControl<CoreLocale>('de')
  licenseTiers = ['basic', 'devEverything']
  licenseTierControl = new FormControl<LicenseTier>('devEverything')
  showOverlay = false

  ngOnInit(): void {
    setDocumentStyles(document)
  }

  editorEnv$ = combineLatest({
    locale: emitDefaultAndSanitize(this.localeControl, 'de'),
    licenseTier: emitDefaultAndSanitize(
      this.licenseTierControl,
      'devEverything',
    ),
  }).pipe(
    map((partialEnv) => ({ ...partialEnv, origin: 'orphan' }) as EditorEnv),
    untilDestroyed(this),
  )
}
