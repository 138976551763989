import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { SurveyCreatorModel } from 'survey-creator-core'

import { isWfaMessage } from '../../types/wfa-message'
import { validate } from '../../utility/validate'
import { safeStructuredClone } from '../utility/lodash-alternatives/safe-structured-clone'
import { WfaForm } from './../../schemas/interfaces/wfa-form'
import { WfaFormDraftUpdateQueryResultJoi } from './../../schemas/joi/wfb/routes/wfa-form-draft/wfa-form-draft-update.query-result.joi'
import { validateWfaForm } from './../../utility/validate-wfa-form'
import { customizeCreatorJson } from './customization/creator-json-customization/customize-creator-json'
import { EditorDataService } from './editor.data-service'
import { EditorStore } from './editor.store'

// see https://surveyjs.io/survey-creator/documentation/get-started-angular
// section `Save and Load Survey Model Schemas`
type Callback = (
  currentChangeNumber: number,
  serverHasAppliedChange: boolean,
) => unknown

export function saveToStoreAndDb(
  store: EditorStore,
  creator: SurveyCreatorModel,
  editorDataService: EditorDataService,
) {
  return (saveNo: number, callback: Callback) => {
    const json = addWfaData(safeStructuredClone(creator.JSON)) as WfaForm
    customizeCreatorJson(json)
    store.setForm(json)

    const wfaForm = validateWfaForm(json)
    if (isWfaMessage(wfaForm)) {
      console.error(`Invalid wfaForm: ${wfaForm.message}`, json)
      callback(saveNo, false)
      return
    }
    store.setFormIsValid(wfaForm)

    editorDataService
      .wfaUpdateWfaFormDraft(wfaForm)
      .pipe(
        catchError((err) => {
          console.error('HTTP request error:', err)
          callback(saveNo, false)
          return of({ message: 'Error occurred, fallback data' })
        }),
      )
      .subscribe((res: any) => {
        const maybeRes = validate(
          WfaFormDraftUpdateQueryResultJoi,
          res?.queryResult,
        )
        if (isWfaMessage(maybeRes)) {
          console.error('HTTP result mismatch type: ', maybeRes.message)
          callback(saveNo, false)
        }

        callback(saveNo, true)
      })
  }
}

function addWfaData(maybeForm: Partial<WfaForm>): WfaForm {
  return {
    ...maybeForm,
    _id: maybeForm.wfaFormVersionId,
    status: 'live',
  } as unknown as WfaForm
}
