import { AbstractControl } from '@angular/forms'

import {
  PraxisKennung,
  WfaFormId,
  WfaFormInfo,
} from '../../../schemas/interfaces'
import { PraxisDetails } from '../../../schemas/interfaces/wfb'
import { I18nPipe } from './../../utility/i18n.pipe'

export function sortAlphabetically(pdList: PraxisDetails[]): PraxisDetails[] {
  return pdList.sort((a, b) =>
    a.identifier.toLowerCase().localeCompare(b.identifier.toLowerCase()),
  )
}

export type KennungAndPraxisDetailsList = [PraxisDetails[], PraxisKennung]
export function filterByKennungSubstring([
  pdList,
  kennungSubstring,
]: KennungAndPraxisDetailsList): PraxisDetails[] {
  return pdList.filter((pd: PraxisDetails) =>
    pd.identifier.toLowerCase().includes(kennungSubstring.toLowerCase()),
  )
}

// kiss > dry
export function sortFormInfosAlphabetically(
  wfi: WfaFormInfo[],
  i18nPipe: I18nPipe,
): WfaFormInfo[] {
  return wfi.sort((a, b) => {
    const aTranslated = i18nPipe.transform(a.title)
    const bTranslated = i18nPipe.transform(b.title)
    return aTranslated.toLowerCase().localeCompare(bTranslated.toLowerCase())
  })
}

export type WfaFormIdAndWfaFormInfos = [WfaFormInfo[], WfaFormId]
export function filterByWfaFormIdSubstring([
  wfaFormInfos,
  wfaFormIdSubstring,
]: WfaFormIdAndWfaFormInfos): WfaFormInfo[] {
  return wfaFormInfos.filter((wfi: WfaFormInfo) =>
    wfi.wfaFormId.toLowerCase().includes(wfaFormIdSubstring.toLowerCase()),
  )
}

export function sortByWfaFormId<T extends { wfaFormId: WfaFormId }>(
  a: T,
  b: T,
) {
  if (a.wfaFormId < b.wfaFormId) {
    return -1
  }
  if (a.wfaFormId > b.wfaFormId) {
    return 1
  }
  return 0
}

export function clearInputValue(control: AbstractControl) {
  control.setValue('')
}
