import { ViewerEnvState } from '../../../schemas/interfaces/viewer-env-state'
import { WfaForm } from '../../../schemas/interfaces/wfa-form'
import { WfaFormResponse } from '../../../schemas/interfaces/wfa-form-response'
import { initialViewerEnvStateDict } from '../../../schemas/utils/viewer-env-state.utils'
import { Maybe } from '../../../types/maybe'

export interface PermalinkState extends Record<string, unknown> {
  env: ViewerEnvState
  form: Maybe<WfaForm>
  formResponse: Maybe<WfaFormResponse>
}

export const initalPermalinkViewerState: PermalinkState = {
  env: initialViewerEnvStateDict['arztDirekt'],
  form: undefined,
  formResponse: undefined,
}
