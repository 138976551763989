import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog'

import { WfaMessage } from './../../../../types/wfa-message'

interface DialogData {
  wfaMessage: WfaMessage
}

@Component({
  selector: 'app-upload-error',
  templateUrl: 'upload-error.component.html',
  standalone: true,
  imports: [MatDialogModule],
})
export class UnlockFormUploadError {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

export function openUnlockFormUploadDialog(
  dialog: MatDialog,
  wfaMessage: WfaMessage,
) {
  dialog.open(UnlockFormUploadError, {
    data: {
      wfaMessage,
    },
    restoreFocus: false,
  })
}
