import { FormResponseOrigin } from '../../schemas/interfaces/form-response-origin'
import { ViewerEnvState } from '../../schemas/interfaces/viewer-env-state'
import { WfaForm } from '../../schemas/interfaces/wfa-form'
import { WfaFormResponse } from '../../schemas/interfaces/wfa-form-response'
import { defaultViewerEnvState } from '../../schemas/utils/viewer-env-state.utils'
import { Maybe } from '../../types/maybe'

export interface ViewerState extends Record<string, unknown> {
  env: ViewerEnvState
  form: Maybe<WfaForm>
  formResponse: Maybe<WfaFormResponse>
  latestResponseOrigin: Maybe<FormResponseOrigin>
}

export const initialViewerState: ViewerState = {
  env: defaultViewerEnvState,
  form: undefined,
  formResponse: undefined,
  latestResponseOrigin: undefined,
}
