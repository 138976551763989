export * from './assets'
export * from './env'
export * from './interfaces'
export * from './interfaces-autogen-from-joi'
export * from './joi'
export * from './joi-custom-validation'
export * from './shared-surveyjs'
export * from './utils'
export { getPermalink, updatePermalink } from './utils/get-permalink'
export { legacyForm } from './utils/wfa-form/form'

import version from './version.json'
const { wfaDefinitionsVersion, wfaVersion } = version
export { wfaDefinitionsVersion, wfaVersion }
