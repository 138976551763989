import { surveyLocalization } from 'survey-core'

import { coreLocales } from '../../../schemas/joi/core-locale.joi'
import { notNullish } from '../../../utility/nullish'
import { getLocalizedTranslations } from '../../utility/get-localized-translations'
import { mergeDeep } from '../../utility/lodash-alternatives/merge-deep'
import { customTranslations } from './custom-translations-for-viewer-and-editor-preview'

export function customizeTranslations(): void {
  coreLocales.forEach((locale) => {
    const localizedTranslations = getLocalizedTranslations(
      customTranslations,
      locale,
    )
    if (notNullish(localizedTranslations))
      surveyLocalization.locales[locale] = mergeDeep(
        surveyLocalization.locales[locale],
        localizedTranslations,
      )
  })
}
