import { WfaForm } from './../../../../../schemas/interfaces/wfa-form'
import { Maybe } from './../../../../../types/maybe'
import { nullish } from './../../../../../utility/nullish'
import { convertToCodename } from './../../../../utility/convert-to-codename'
import { getCurrentDateTime } from './../../../../utility/get-current-date-time'

export function getWfaFormId(
  wfaForm: Maybe<Partial<WfaForm>>,
  date: Date = new Date(),
): string {
  return (wfaForm as WfaForm)?.wfaFormId ?? getPristineWfaFormId(wfaForm, date)
}

function getPristineWfaFormId(
  wfaForm: Maybe<Partial<WfaForm>>,
  date: Date = new Date(),
): string {
  const dateTime = getCurrentDateTime(date)
  if (nullish(wfaForm)) {
    return `form-${dateTime}`
  }

  const title = wfaForm.title?.de ?? wfaForm.title ?? 'form'
  const description = wfaForm.description?.de ?? wfaForm.description ?? ''
  const candidateWfaFormId = convertToCodename(`${title}-${description}`)
  if (candidateWfaFormId === 'form') {
    return `form-${dateTime}`
  }

  return candidateWfaFormId
}
