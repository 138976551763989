import { MatDialog } from '@angular/material/dialog'
import { Model } from 'survey-core'

import { ViewerEnvState } from '../../../schemas/interfaces/viewer-env-state'
import { customizeFileDeletion } from './file-deletion/cutomize-file-deletion'
import { customizeFileUpload } from './file-upload/customize-file-upload'

export function customizeFileUploadQuestion(
  surveyModel: Model,
  dialog: MatDialog,
  env: ViewerEnvState,
): void {
  customizeFileUpload(surveyModel)
  customizeFileDeletion(surveyModel, dialog, env)
}
