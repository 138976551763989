import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { MatTableModule } from '@angular/material/table'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'
import { filter, switchMap } from 'rxjs/operators'

import { WfaEnvService } from '../../../../environments/wfa-env.service'
import { Id, WfaFormDraft } from '../../../../schemas/interfaces'
import { I18nPipe } from '../../../utility/i18n.pipe'
import { getLastElement } from '.././../../utility/array/get-last-element'
import { mongoIdToLocaleString } from '.././../../utility/mongo-id-to-locale-string'
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component'
import { navigateToAdisEditor } from '../navigate-to-adis-editor'
import { updateInfos, ZsSupportService } from '../zs-support.service'
import { ZsSupportCommsService } from '../zs-support-comms.service'
import { PraxisId } from './../../../../schemas/interfaces/praxis-id'
import { WfaForm } from './../../../../schemas/interfaces/wfa-form'
import { WfaFormId } from './../../../../schemas/interfaces/wfa-form-id'
import { notNullish } from './../../../../utility/nullish'
import { copyFormOrDraft } from './../copy-form-or-draft'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-form-draft-table',
  styleUrls: [
    './form-draft-table.component.scss',
    '../mat-card.scss',
    '../form-table/form-table.component.scss',
  ],
  templateUrl: './form-draft-table.component.html',
  imports: [
    ActionButtonsComponent,
    CommonModule,
    I18nPipe,
    MatCardModule,
    MatTableModule,
    RxLet,
    TranslateModule,
  ],
})
export class FormDraftTableComponent {
  readonly getLastElement = getLastElement
  readonly mongoIdToLocaleString = mongoIdToLocaleString

  displayedColumns: string[] = ['identifier', 'actions']

  constructor(
    private comms: ZsSupportCommsService,
    private router: Router,
    private translate: TranslateService,
    public wfaEnvService: WfaEnvService,
    public zsSupportService: ZsSupportService,
  ) {}

  copyDraft(wfaFormVersionId: Id, wfaFormId: WfaFormId, praxisId: PraxisId) {
    copyFormOrDraft(
      wfaFormVersionId,
      wfaFormId,
      praxisId,
      this.zsSupportService,
      this.comms,
      this.comms.getDraft(wfaFormVersionId).pipe(filter(notNullish)),
      (f: WfaForm) => this.comms.createFormDraftUsingForm(f),
    )
      .pipe(untilDestroyed(this))
      .subscribe()
  }

  deleteDraft(wfaFormVersionId: Id) {
    this.comms
      .deleteFormDraft(wfaFormVersionId)
      .pipe(
        switchMap(() =>
          this.comms.getFormAndDraftInfos(
            this.zsSupportService.getPraxisId('deleteDraft'),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(updateInfos(this.zsSupportService))
  }

  openEditor(wfaFormVersionId: Id) {
    this.zsSupportService.showSpinner$.next(true)
    this.comms
      .getDraft(wfaFormVersionId)
      .pipe(filter(notNullish), untilDestroyed(this))
      .subscribe((wfaFormDraft: WfaFormDraft) => {
        this.zsSupportService.showSpinner$.next(false)
        navigateToAdisEditor(wfaFormDraft, this.router, this.wfaEnvService)
      })
  }

  publishDraft(wfaFormVersionId: Id) {
    this.comms
      .publishFormDraft(wfaFormVersionId)
      .pipe(
        switchMap(() =>
          this.comms.getFormAndDraftInfos(
            this.zsSupportService.getPraxisId('publishDraft'),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(updateInfos(this.zsSupportService))
  }
}
