import { SurveyjsResponse } from '../../../../schemas/interfaces/surveyjs-response'
import { WfaForm } from '../../../../schemas/interfaces/wfa-form'
import { getQuestions } from '../../../../utility/get-questions'
import {
  QUESTION_TYPES_MULTI_SELECT,
  QUESTION_TYPES_SINGLE_SELECT,
} from '../../types'
import { WfaQuestionWithInputType } from './../../../../schemas/custom-interfaces/wfa-question-with-input-type'
import { ResponseType } from './../../../../schemas/custom-interfaces/wfa-question-with-input-type'
import { WfaQuestion } from './../../../../schemas/interfaces/wfa-question/wfa-question'

const formResponseDictionary: Record<string, string | number | boolean> = {
  boolean: true,
  text: 'Das ist eine Freitext-Antwort',
  number: 1,
  date: '2000-01-01',
}

function lookUpFormResponseValue(
  type: string,
): string | number | boolean | undefined {
  return formResponseDictionary[type]
}

function extractFirstResponseOption(
  wfaQuestion: WfaQuestion,
): string | undefined {
  const responseOptions = (wfaQuestion as unknown as any)['choices']
  if (responseOptions === undefined) return undefined
  const firstResponseOption = responseOptions[0]
  if (firstResponseOption === undefined) return undefined
  return firstResponseOption['value']
}

function packResponseOption(wfaQuestion: WfaQuestion): string[] | undefined {
  const responseOption = extractFirstResponseOption(wfaQuestion)
  if (responseOption === undefined) return undefined
  return [responseOption]
}

function createQuestionResponse(
  wfaQuestion: WfaQuestionWithInputType,
): ResponseType | undefined {
  const questionType = wfaQuestion.type
  if (QUESTION_TYPES_SINGLE_SELECT.includes(questionType))
    return extractFirstResponseOption(wfaQuestion)
  if (QUESTION_TYPES_MULTI_SELECT.includes(questionType))
    return packResponseOption(wfaQuestion)
  return lookUpFormResponseValue(
    wfaQuestion['inputType'] ?? wfaQuestion['wfaInputType'] ?? questionType,
  )
}

export function generateFormResponse(wfaForm: WfaForm): SurveyjsResponse {
  const surveyjsResponse: SurveyjsResponse = {}
  const wfaQuestions = getQuestions(wfaForm)
  wfaQuestions.forEach((wfaQuestion) => {
    const questionIdentifier = wfaQuestion.name
    surveyjsResponse[questionIdentifier] = createQuestionResponse(wfaQuestion)
  })
  return surveyjsResponse
}
