import { SurveyjsResponse } from '../../../../schemas/interfaces/surveyjs-response'
import { WfaForm } from '../../../../schemas/interfaces/wfa-form'
import { getQuestions } from '../../../../utility/get-questions'
import { Locale, QUESTION_TYPES_SELECT, ResponseOption } from '../../types'
import { initialViewerState } from '../../viewer.state'
import { HumanReadable } from './../../../../schemas/interfaces/human-readable'
import { WfaQuestion } from './../../../../schemas/interfaces/wfa-question/wfa-question'

const fallbackLocale = initialViewerState.env.locale

export function convertFormResponseToHumanReadable(
  wfaForm: WfaForm,
  surveyjsResponse: SurveyjsResponse,
  locale: Locale = fallbackLocale,
): HumanReadable {
  const wfaQuestions = getQuestions(wfaForm)
  const convertedFormResponse: SurveyjsResponse = {}

  Object.entries(surveyjsResponse).forEach(([questionId, questionResponse]) => {
    const wfaQuestion = getQuestionByIdentifier(wfaQuestions, questionId)
    if (wfaQuestion === undefined) return
    const convertedQuestionId = convertQuestionIdentifier(wfaQuestion, locale)
    const convertedResponse =
      isSelectQuestion(wfaQuestion) === true
        ? convertResponseOfSelectQuestion(
            wfaQuestion,
            questionResponse as string | string[],
            locale,
          )
        : questionResponse
    Object.assign(convertedFormResponse, {
      [convertedQuestionId]: convertedResponse,
    })
  })
  return convertedFormResponse
}

export function getQuestionByIdentifier(
  wfaQuestions: WfaQuestion[],
  questionIdentifier: string,
): WfaQuestion | undefined {
  return wfaQuestions.find((question) => question.name === questionIdentifier)
}

export function convertQuestionIdentifier(
  wfaQuestion: WfaQuestion,
  locale: Locale,
): string {
  if (wfaQuestion['title'] == undefined) return wfaQuestion.name
  return (
    (wfaQuestion['title'][locale] as unknown as string) ??
    (wfaQuestion['title'][fallbackLocale] as unknown as string)
  )
}

export function isSelectQuestion(wfaQuestion: WfaQuestion): boolean {
  const questionType = wfaQuestion.type
  if (QUESTION_TYPES_SELECT.includes(questionType)) return true
  return false
}

export function convertResponseOfSelectQuestion(
  wfaQuestion: WfaQuestion,
  questionResponse: string | string[],
  locale: Locale,
): string | string[] | undefined {
  const responseOptions: ResponseOption[] = (wfaQuestion as unknown as any)[
    'choices'
  ]
  if (typeof questionResponse === 'string')
    return convertResponseOption(responseOptions, questionResponse, locale)
  return questionResponse
    .map((response: string) =>
      convertResponseOption(responseOptions, response, locale),
    )
    .filter((option) => option != undefined) as string[]
}

export function convertResponseOption(
  responseOptions: ResponseOption[],
  questionResponse: string,
  locale: Locale,
): string | undefined {
  const responseOption = responseOptions.find((option) => {
    if (typeof option === 'string') return option === questionResponse
    if (
      typeof option === 'object' &&
      option !== null &&
      Object.keys(option).includes('value')
    )
      return option.value === questionResponse
    return false
  })
  if (responseOption == undefined || typeof responseOption === 'string')
    return responseOption as string
  if (typeof responseOption.text === 'string') return responseOption.text
  return responseOption.text[locale] ?? responseOption.text[fallbackLocale]
}
