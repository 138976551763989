import dayjs from 'dayjs'

import { Maybe } from '../../../types/maybe'
import { nullish } from '../../../utility/nullish'

export function formatDate(
  date: Maybe<string | Date>,
  format: '.' | '-',
): Maybe<string> {
  if (nullish(date)) return undefined
  if (format === '-') return dayjs(date).format('DD-MM-YYYY')
  return dayjs(date).format('DD.MM.YYYY')
}
