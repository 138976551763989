import { Router } from '@angular/router'

import { WfaEnvService } from '../../../environments/wfa-env.service'
import { WfaFormDraft } from '../../../schemas/interfaces'
import { AdisEditorInput } from '../../../schemas/interfaces/adis/adis-editor-input'
import { getParentUrl } from '../../../utility/get-parent-url'

export function navigateToAdisEditor(
  wfaFormDraft: WfaFormDraft,
  router: Router,
  _wfaEnvService: WfaEnvService,
) {
  const adisEditorInput: AdisEditorInput = {
    wfaFormDraft,
  }
  const url = `${getParentUrl(router.url)}/wfa/adis-editor`
  router.navigateByUrl(url, { state: { adisEditorInput } })
}
