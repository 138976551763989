import { compareVersions } from 'compare-versions'

import { Version } from '../schemas/interfaces'
import { getLastElement } from './../app/utility/array/get-last-element'
import { Maybe } from './../types/maybe'

type VersionDict<T> = {
  [key in Version]: T
}

export function selectVersion<T>(
  currentVersion: Version,
  versionDict: VersionDict<T>,
): Maybe<T> {
  const dictVersions = Object.keys(versionDict)
  if (dictVersions.length === 0) return undefined

  try {
    const sortedVersions = dictVersions.sort(compareVersions)
    const allowedVersions = sortedVersions.filter(
      (v) => compareVersions(v, currentVersion) < 1,
    )
    const selected = getLastElement(allowedVersions) ?? sortedVersions[0]
    return versionDict[selected]
  } catch (e) {
    return undefined
  }
}
